import { useD3 } from '../common/hooks/useD3';
import { max } from 'd3-array';
import { axisLeft, axisTop } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import { transition } from 'd3-transition';
import { interpolate } from 'd3-interpolate';

export const HorizontalBarChart = (props) => {
  const render = (svg) => {
    let topContainer = svg
      .selectAll('g.topContainer')
      .data([0])
      .enter()
      .append('g')
      .attr('class', 'topContainer')
      .attr('transform', 'translate(0,0)');

    topContainer = svg.select('g.topContainer');

    const margin = { left: 25 };
    const [width, height] = props.size;
    const [xKey, yKey] = props.axisKeys;
    const maxNumber = max(props.data, (d) => d[xKey]);

    const xScale = scaleLinear().domain([0, maxNumber]).range([0, 80]);

    const yScale = scaleBand()
      .domain(props.data.map((d) => d[yKey]))
      .range([0, height])
      .padding(0.4);

    const yAxis = (g, scale = yScale) => {
      g.attr('class', 'yAxis text-sm font-semibold')
        .attr('transform', `translate(${margin.left}, 0)`)
        .call(axisLeft(scale))
        .call((g) => g.selectAll('line').remove());
    };

    const defaultTransition = transition().duration(1500);

    topContainer
      .selectAll('g.yAxis')
      .data([0])
      .enter()
      .append('g')
      .call(yAxis, yScale);

    topContainer
      .select('g.yAxis')
      .transition(defaultTransition)
      .call(yAxis, yScale);

    topContainer.select('g.yAxis').select('.domain').remove();

    const rectGradientId = 'rectGradientId';

    const svgDefs = svg.selectAll('defs').data([0]).enter().append('defs');

    const percentGradient = svgDefs
      .append('linearGradient')
      .attr('id', rectGradientId);

    percentGradient
      .append('stop')
      .attr('stop-color', '#11CCEA')
      .attr('offset', '0');

    percentGradient
      .append('stop')
      .attr('stop-color', '#e9fbfd')
      .attr('offset', '1');

    topContainer
      .selectAll('rect')
      .data(props.data, (d) => d[yKey])
      .enter()
      .append('rect')
      .attr('fill', (d) => `url(#${rectGradientId})`)
      .attr('x', margin.left)
      .attr('y', (d) => yScale(d[yKey]))
      .attr('ry', 4)
      .attr('width', 0)
      .attr('height', yScale.bandwidth());

    topContainer
      .selectAll('rect')
      .data(props.data, (d) => d[yKey])
      .exit()
      .remove();

    topContainer
      .selectAll('rect')
      .transition(defaultTransition)
      .attr('y', (d) => yScale(d[yKey]))
      .attr('height', yScale.bandwidth())
      .attr('width', (d) => `${xScale(d[xKey])}%`);

    topContainer
      .selectAll('.barText')
      .data(props.data, (d) => d[yKey])
      .enter()
      .append('text')
      .attr('class', 'barText text-sm font-semibold')
      .attr('y', (d) => yScale(d[yKey]) + yScale.bandwidth() / 2)
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'black')
      .text(0)
      .each(function (d) {
        this._current = {};
        this._current[xKey] = 0;
        this._current.textTranslateState = `translate(5px,0)`;
      });

    topContainer
      .selectAll('.barText')
      .data(props.data, (d) => d[yKey])
      .exit()
      .remove();

    topContainer
      .selectAll('.barText')
      .transition(defaultTransition)
      .attr('y', (d) => yScale(d[yKey]) + yScale.bandwidth() / 2)
      .styleTween('transform', function (d) {
        const endTranslateState = `translate(calc(${xScale(d[xKey])}% + ${
          margin.left + 3
        }px))`;
        const interpolateToEnd = interpolate(
          this._current.textTranslateState,
          endTranslateState,
        );
        return (t) => {
          return (this._current.textTranslateState = interpolateToEnd(t));
        };
      })
      .textTween(function (d) {
        const interpolateToD = interpolate(this._current[xKey], d[xKey]);
        return (t) => {
          return (this._current[xKey] = interpolateToD(t).toFixed(0));
        };
      });
  };

  const svgRef = useD3(render, props.data);

  return (
    <svg
      style={{ height: props.size[1] }}
      className={'flex w-full'}
      ref={svgRef}
    ></svg>
  );
};
