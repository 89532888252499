import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function PriorActionsTable({ loading, data }) {
  return (
    <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-8 pb-8 text-brandText shadow-md'>
      <table className='w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
        <thead>
          <tr className='text-base'>
            <th className='sm-pl-14 p-4 pl-9 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Date
            </th>
            <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Issue
            </th>
            <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Action
            </th>
            <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Staff
            </th>
            <th className='p-4 pr-9 pt-1 pb-1 text-left text-base font-medium sm:pr-14 md:text-2xl'>
              Type
            </th>
          </tr>
        </thead>
        <tbody className='bg-white text-brandText'>
          {data?.map((item) => (
            <tr key={item?.id}>
              <td className='p-4 pl-9 pt-2 pb-0.5 font-medium sm:pl-14'>
                {item?.created_at}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>{item?.issue}</td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.teacher_actions}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.author?.first_name} {item?.author?.last_name}
              </td>
              <td className='p-4 pr-9 pt-2 pb-0.5 font-medium sm:pr-14'>
                {item?.is_referral ? 'Referral' : 'Note'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <FormSpinner loading={loading} />
      <NoRecords loading={loading} data={data} />
    </div>
  );
}
