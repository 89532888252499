import { useEffect, useState } from 'react';
import FormSpinner from './formComponents/FormSpinner';

export default function CourseDetailsGradeTable({ loading, data }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (loading === false && data !== null && data !== undefined) {
      const filteredGradeCounts = data.grade_counts.filter((gradeCount) => {
        // Check if any grade count value other than '0' exists
        return Object.keys(gradeCount).some(
          (key) => key !== 'name' && gradeCount[key] !== 0,
        );
      });

      // Update the filteredData state with the filtered grade counts
      setFilteredData({ ...data, grade_counts: filteredGradeCounts });
    }
  }, [loading]);

  const calculatePercentage = (gradeCount, total) => {
    return ((gradeCount / total) * 100).toFixed(2);
  };

  return (
    <div className='flex h-full w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
      {loading ? (
        <div>
          <FormSpinner loading={loading} />
        </div>
      ) : (
        <table className='w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          <thead>
            <tr className='text-xl font-bold text-brandText'>
              <th className='p-4 pl-14 pt-1 pb-1 text-left'>Teacher</th>
              {data?.possible_grades?.map((item, itemIdx) => (
                <th key={itemIdx} className='p-4 pt-1 pb-1 text-left'>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='bg-white text-brandText'>
            {filteredData?.grade_counts?.map((item, itemIdx) => {
              // Calculate the total for each grade (column-wise)
              const gradeTotal = data.possible_grades.reduce((acc, grade) => {
                return acc + item[grade];
              }, 0);

              return (
                <tr key={itemIdx} className='border-b-2 border-b-gray-100'>
                  <td className='p-4 pl-14 pt-2 pb-0.5 font-medium'>
                    {item.name}
                  </td>
                  {data.possible_grades.map((grade, gradeIdx) => (
                    <td key={gradeIdx} className='p-4 pt-2 pb-0.5 font-medium'>
                      {isNaN(gradeTotal)
                        ? 0
                        : calculatePercentage(item[grade], gradeTotal)}
                      % ({item[grade]})
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {filteredData?.grade_counts?.length < 1 && (
        <div className='flex h-full items-center justify-center text-4xl font-normal text-gray-200'>
          No Records
        </div>
      )}
    </div>
  );
}
