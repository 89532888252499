import supervisorIcon from '../../../images/circle_icons/supervisor_caseload.svg';

export default function SupervisorCard({ data }) {
  return (
    <div className='flex w-full flex-row space-x-8 text-brandText'>
      <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img className='h-16 w-16' src={supervisorIcon} alt='Grades' />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-3xl font-medium'>Supervisor</h2>
            <p className='ml-2 text-sm'>Caseload</p>
          </div>
        </div>
        <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          {/* {data?.length >= 1 ? ( */}
          <thead>
            <tr className='text-base'>
              <th className='p-4 pl-20 pt-1 pb-1 text-left text-xl font-medium'>
                Supervisor
              </th>
              <th className='p-4 pt-1 pb-1 text-left text-xl font-medium'>
                Treatment
              </th>
            </tr>
          </thead>
          {/* ) : (
            <thead>
              <span className='py-1 text-xl'>&nbsp;</span>
            </thead>
          )} */}
          <tbody className='bg-white text-brandText'>
            {data?.length >= 1 &&
              data?.map((item) => (
                <tr key={item?.id}>
                  <td className='p-4 py-2 pl-20 font-medium'>
                    {item?.placeholder}
                  </td>
                  <td className='p-4 py-2 font-medium'>{item?.placeholder}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {data?.length === 0 && (
          <div className='ml-10 mt-7 text-lg font-medium'>No Records</div>
        )}
        <p className='mt-5 text-center text-xl text-red-400'>
          Missing Data Source!
        </p>
      </div>
    </div>
  );
}
