import PasswordUpdateForm from '../components/Password/PasswordUpdateForm';
import { useParams } from 'react-router-dom';

export default function PasswordUpdate() {
  const { uidb64, token } = useParams();

  return (
    <div>
      <PasswordUpdateForm uidb64={uidb64} token={token} />
    </div>
  );
}
