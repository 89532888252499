import SidebarLayout from '../../components/SidebarLayout';
import MtssMemberManagementUpdateForm from '../../components/sections/MTSS/MtssMemberManagementUpdateForm';
import AccessWrapper from '../../components/AccessWrapper';
import AuthContext from '../../context/AuthContext';
import React, { useEffect, useContext } from 'react';

export default function MtssMemberManagementUpdate() {
  let { baseUrl, postPageView, user } = useContext(AuthContext);

  useEffect(() => {
    postPageView(
      baseUrl,
      user.name,
      user.role,
      'mtss-management/member-update',
    );
  }, []);
  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={<MtssMemberManagementUpdateForm />}
          heading={'Update User'}
        />
      </div>
    </AccessWrapper>
  );
}
