export default function AtAGlaceInfoCard({ student }) {
  return (
    <div className='flex h-full w-full flex-col justify-evenly overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white px-8 text-xs font-medium text-brandText shadow-md md:w-40 md:px-4 xl:w-48 xl:px-8'>
      <p>
        <span className='font-semibold'>Grade:</span> {student?.grade_level}
      </p>
      <p>
        <span className='font-semibold'>School Name:</span> <br />
        {student?.school?.name}
      </p>
      <p>
        <span className='font-semibold'>Birthdate:</span> {student?.dob}
      </p>
      <p>
        <span className='font-semibold'>Gender:</span> {student?.gender}
      </p>
      <p>
        <span className='font-semibold'>Ethnicity:</span> {student?.fer?.name}
      </p>
    </div>
  );
}
