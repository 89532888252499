import StudentSidebarLayout from '../../components/StudentSidebarLayout';
import StudentPageTopper from '../../components/StudentPageTopper';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useNavigate, useParams, Link } from 'react-router-dom';
import NotesTable from '../../components/student_components/NotesTable';
import AAGCreateANote from '../../components/student_components/AAGCreateANote';
import AccessWrapper from '../../components/AccessWrapper';

export default function Notes() {
  const { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.studentId;
  const [loading, setLoading] = useState(false);

  /* state variable to hold selected student */
  const [student, setStudent] = useState();
  const [studentReferrals, setStudentReferrals] = useState();
  const [studentAllReferrals, setStudentAllReferrals] = useState();
  const [studentReferralsDisplay, setStudentReferralsDisplay] = useState();
  const [referralTimeframe, setReferralTimeframe] = useState('Notes');
  const [showingRecentReferrals, setShowingRecentReferrals] = useState(true);
  const [buttonText, setButtonText] = useState('Show All');

  useEffect(() => {
    getStudent();
    getStudentReferrals();
    getStudentAllReferrals();
    postPageView(baseUrl, user.name, user.role, 'student/notes');
  }, []);

  let getStudent = async () => {
    let response = await fetch(`${baseUrl}/sis/students/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudent(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentReferrals = async () => {
    setLoading(true);
    let response = await fetch(
      `${baseUrl}/mtss/referrals/?student=${id}&recent=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentReferrals(data);
      setStudentReferralsDisplay(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  let getStudentAllReferrals = async () => {
    let response = await fetch(`${baseUrl}/mtss/referrals/?student=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentAllReferrals(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  const handleSubmit = () => {
    if (showingRecentReferrals) {
      setStudentReferralsDisplay(studentAllReferrals);
      setReferralTimeframe('Notes');
      setShowingRecentReferrals(false);
      setButtonText('Show Recent');
    } else {
      setStudentReferralsDisplay(studentReferrals);
      setReferralTimeframe('Notes');
      setShowingRecentReferrals(true);
      setButtonText('Show All');
    }
  };

  const navigateToForm = () => {
    navigate('/mtss/note-referral', {
      state: {
        studentFirstName: student?.first_name,
        studentLastName: student?.last_name,
        studentId: student?.id,
      },
    });
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <StudentSidebarLayout
          propComponents={
            <div>
              <StudentPageTopper student={student} />
              <div className='relative -top-14 mx-auto'>
                <div className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                      Create a Note
                    </h1>
                    <div className='mx-auto flex flex-row justify-between'>
                      <AAGCreateANote
                        student={student}
                        update={getStudentReferrals}
                      />
                    </div>
                  </div>
                </div>
                <div className='mx-auto mt-10 bg-gray-100 px-4 pt-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl'>
                    <h2 className='mt-3 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                      {referralTimeframe}
                    </h2>
                  </div>
                  <div className='mx-auto mt-5 flex w-full max-w-7xl flex-row sm:mt-7'>
                    <NotesTable
                      loading={loading}
                      data={studentReferralsDisplay}
                    />
                  </div>
                </div>
                <div className='mx-auto bg-gray-100 px-4 pt-5 pb-12 sm:px-6 sm:pb-16 lg:px-8 lg:pb-20'>
                  <div className='mx-auto flex w-full max-w-7xl flex-row justify-end'>
                    <button
                      onClick={handleSubmit}
                      className='rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                    >
                      {buttonText}
                    </button>
                    <button
                      onClick={navigateToForm}
                      className='ml-3 rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                    >
                      Create Referral
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          heading={'Student Report'}
          id={id}
          heading2={'Notes'}
          notesCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
