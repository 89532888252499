import StudentSidebarLayout from '../../components/StudentSidebarLayout';
import StudentPageTopper from '../../components/StudentPageTopper';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import ActionsTable from '../../components/student_components/ActionsTable';
import PriorActionsTable from '../../components/student_components/PriorActionsTable';
import AccessWrapper from '../../components/AccessWrapper';

export default function Actions() {
  const { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.studentId;
  const [loadingCurrent, setLoadingCurrent] = useState(false);
  const [loadingPrior, setLoadingPrior] = useState(false);

  /* state variable to hold selected student */
  const [student, setStudent] = useState();
  const [studentActions, setStudentActions] = useState();
  const [studentPriorActions, setStudentPriorActions] = useState();
  const [studentAllPriorActions, setStudentAllPriorActions] = useState();
  const [studentPriorActionsDisplay, setStudentPriorActionsDisplay] =
    useState();
  const [actionTimeframe, setActionTimeframe] = useState('Prior Actions');
  const [showingRecentActions, setShowingRecentActions] = useState(true);
  const [buttonText, setButtonText] = useState('Show All');

  useEffect(() => {
    getStudent();
    getStudentActions();
    getStudentPriorActions();
    getStudentAllPriorActions();
    postPageView(baseUrl, user.name, user.role, 'student/actions');
  }, []);

  let getStudent = async () => {
    let response = await fetch(`${baseUrl}/sis/students/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudent(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentActions = async () => {
    setLoadingCurrent(true);
    let response = await fetch(
      `${baseUrl}/mtss/interventions/?student=${id}&status=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentActions(data);
      setLoadingCurrent(false);
    } else if (response.status === 403) {
      setLoadingCurrent(false);
      navigate('/unauthorized');
    }
  };

  let getStudentPriorActions = async () => {
    setLoadingPrior(true);
    let response = await fetch(
      `${baseUrl}/mtss/interventions/?student=${id}&status=0&recent=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentPriorActions(data);
      setStudentPriorActionsDisplay(data);
      setLoadingPrior(false);
    } else if (response.status === 403) {
      setLoadingPrior(false);
      navigate('/unauthorized');
    }
  };

  let getStudentAllPriorActions = async () => {
    let response = await fetch(
      `${baseUrl}/mtss/interventions/?student=${id}&status=0`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentAllPriorActions(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  const handleSubmit = () => {
    if (showingRecentActions) {
      setStudentPriorActionsDisplay(studentAllPriorActions);
      setActionTimeframe('Prior Actions');
      setShowingRecentActions(false);
      setButtonText('Show Recent');
    } else {
      setStudentPriorActionsDisplay(studentPriorActions);
      setActionTimeframe('Prior Actions');
      setShowingRecentActions(true);
      setButtonText('Show All');
    }
  };

  const navigateToForm = () => {
    navigate(`/mtss/add-action`, {
      state: {
        studentFirstName: student?.first_name,
        studentLastName: student?.last_name,
        studentId: student?.id,
      },
    });
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <StudentSidebarLayout
          propComponents={
            <div>
              <StudentPageTopper student={student} />
              <div className='relative -top-14 mx-auto'>
                <div className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                      Current Actions
                    </h1>
                    <div className='mx-auto flex flex-row justify-between space-x-8 '>
                      <ActionsTable
                        loading={loadingCurrent}
                        data={studentActions}
                      />
                    </div>
                  </div>
                </div>
                <div className='mx-auto mt-10 bg-gray-100 px-4 pt-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl'>
                    <h2 className='mt-3 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                      {actionTimeframe}
                    </h2>
                  </div>
                  <div className='mx-auto mt-5 flex w-full max-w-7xl flex-row sm:mt-7'>
                    <PriorActionsTable
                      loading={loadingPrior}
                      data={studentPriorActionsDisplay}
                    />
                  </div>
                </div>
                <div className='mx-auto bg-gray-100 px-4 pb-12 pt-5 sm:px-6 sm:pb-16 lg:px-8 lg:pb-20'>
                  <div className='mx-auto flex w-full max-w-7xl flex-row justify-end'>
                    <button
                      onClick={handleSubmit}
                      className='rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                    >
                      {buttonText}
                    </button>
                    {user?.role !== 'Teacher' && (
                      <button
                        onClick={navigateToForm}
                        className='ml-3 rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                      >
                        Add Action
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
          heading={'Student Report'}
          id={id}
          heading2={'Actions'}
          actionsCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
