import { Oval } from 'react-loader-spinner';

export default function Spinner({ loading }) {
  return (
    <>
      {loading && (
        <div className='fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-white/90 duration-200 ease-in-out'>
          <div>
            <Oval
              height={80}
              width={80}
              color='#0CB2DA'
              wrapperStyle={{}}
              wrapperClass=''
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor='#e5e7eb'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </div>
      )}
    </>
  );
}
