import { useD3 } from "../common/hooks/useD3";
import { scaleLinear } from "d3-scale";
import { transition } from 'd3-transition';
import { interpolate } from 'd3-interpolate';
import colors from 'tailwindcss/colors';

export const PercentBar = (props) => {

    const render = (svg) => {       
        const lastNumber = props.totalNumber;
        const firstNumber = props.currentNumber;

        const xScale = scaleLinear()
            .domain([0, lastNumber])
            .range([0, 100]);

        const data = [lastNumber, firstNumber];

        const svgDefs = svg
            .selectAll('defs')
            .data([0])
            .enter()
            .append('defs');

        const percentGradientId = 'percentGradient';

        const percentGradient = svgDefs
            .append('linearGradient')
            .attr('id', percentGradientId);

        percentGradient.append('stop')
            .attr('stop-color', colors.gray[50])
            .attr('offset', '0');

        percentGradient.append('stop')
           .attr('stop-color', '#0CB2DA')
           .attr('offset', '1');

        svg
            .selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr('stroke', (d,i) => {
                if (i == 0) {
                    return 'gainsboro'
                } 
                return 'none'; 
            })
            .attr('fill', (d,i) => {
                if (i == 1) {
                    return `url(#${percentGradientId})`
                }

                return props.barScale(i);
            })
            .attr("rx", 8)
            .attr("x", 0)
            .attr("y", 0)
            .attr("height", 20)
            .each(function (d) {
                this._current = 0;
            });

        svg
            .selectAll("rect")
            .data(data)
            .transition(transition())
            .duration((d, i) => i * 1500)
            .attrTween("width", function (d) {
                const interpolateNumber = interpolate(this._current, d);
                return (t) => {
                    const currentNumber = interpolateNumber(t);
                    this._current = currentNumber;
                    const scaledNumber = xScale(currentNumber);
                    return `${scaledNumber}%`;
                }
            });
    }

    const svgRef = useD3(render, props.totalNumber, props.currentNumber);

    return (
        <>
            <svg className='w-full h-5' ref={svgRef} />            
        </>
    );
}; 
