export default function StudentPageTopper({ student }) {
  return (
    <div className='bg-white md:bg-gray-100'>
      <div className=' w-full px-4 pb-2.5 sm:px-6 lg:flex lg:flex-col lg:px-8'>
        <div className='flex items-center justify-between pt-3 md:pt-0'>
          <div>
            <p className='text-xl font-bold text-brandText sm:text-2xl'>
              {student?.first_name} {student?.last_name}
            </p>
            <p className='text-sm font-bold text-brandText'>
              {student?.local_id}
            </p>
          </div>
          <div className='flex flex-row justify-between space-x-3'>
            <div
              className={`${
                student?.has_iep === '1' ? '' : 'hidden'
              } rounded-md bg-yellow-300 px-4 py-1 font-semibold text-brandText shadow-md sm:py-2 lg:px-7`}
            >
              <span>IEP</span>
            </div>
            <div
              className={`${
                student?.has_504 === '1' ? '' : 'hidden'
              } rounded-md bg-yellow-300 px-4 py-1 font-semibold text-brandText shadow-md sm:py-2 sm:px-7`}
            >
              <span>504</span>
            </div>
            <div
              className={`hidden rounded-md bg-yellow-300 px-7 py-2 font-semibold text-brandText shadow-md`}
            >
              <span>LEP</span>
            </div>
          </div>
        </div>
      </div>
      <div className=' flex h-20 flex-row md:h-28'>
        <div className='student-page-topper-container flex h-full w-80 bg-white'></div>
        <div className='h-full w-full bg-white'></div>
      </div>
    </div>
  );
}
