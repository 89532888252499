import { useState } from 'react';
import AtAGlanceModal from './AtAGlanceModal';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function StudentsTable({ loading, data }) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();

  const elaCalc = (item) => {
    if (item === null) {
      return ' bg-slate-100 ';
    } else if (item < 16) {
      return ' bg-[#dc2626] ';
    } else if (item < 26) {
      return ' bg-yellow-500 ';
    } else if (item < 100) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-slate-100 ';
    }
  };

  const mathCalc = (item) => {
    if (item === null) {
      return ' bg-slate-100 ';
    } else if (item < 16) {
      return ' bg-[#dc2626] ';
    } else if (item < 26) {
      return ' bg-[#facc15] ';
    } else if (item < 100 || item === 100) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-slate-100 ';
    }
  };

  const attendanceCalc = (item) => {
    if (item === null) {
      return ' bg-slate-100 ';
    } else if (item > 10) {
      return ' bg-[#dc2626] ';
    } else if (item > 5) {
      return ' bg-[#facc15] ';
    } else if (item < 11) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-slate-100 ';
    }
  };

  const behavioralCalc = (item) => {
    if (item === null) {
      return ' bg-slate-100 ';
    } else if (item > 10) {
      return ' bg-[#dc2626] ';
    } else if (item > 0) {
      return ' bg-[#facc15] ';
    } else if (item === 0) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-slate-100  ';
    }
  };

  const totalCalc = (total) => {
    let className = '';
    if (total === null) {
      className = ' bg-slate-100 ';
    } else if (total === 0) {
      className = ' bg-green-800 ';
    } else if (total === 1) {
      className = ' bg-green-700 ';
    } else if (total === 2) {
      className = ' bg-green-500 ';
    } else if (total === 3) {
      className = ' bg-lime-300 ';
    } else if (total === 4) {
      className = ' bg-yellow-500 ';
    } else if (total === 5) {
      className = ' bg-red-700 ';
    } else if (total === 6) {
      className = ' bg-red-700 ';
    } else if (total === 7) {
      className = ' bg-red-800 ';
    } else if (total === 8) {
      className = ' bg-red-800 ';
    } else {
      className = ' bg-slate-100 ';
    }
    return className;
  };

  const handleClick = (item) => {
    setOpen(true);
    setId(item.id);
  };

  return (
    <div className='flex w-full flex-row space-x-8 text-brandText'>
      <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
        <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          <thead>
            <tr className='sm:text-md text-base'>
              <th className='p-3 pt-1 pb-1 pl-9 text-left font-medium sm:p-4 sm:pl-14'>
                Student
              </th>
              <th className='p-2.5 pt-1 pb-1 text-center font-medium sm:p-4'>
                ELA
              </th>
              <th className='p-2.5 pt-1 pb-1 text-center font-medium sm:p-4'>
                Math
              </th>
              <th className='p-3 pt-1 pb-1 text-center font-medium sm:p-4'>
                Attendance
              </th>
              <th className='p-3 pt-1 pb-1  text-center font-medium sm:p-4'>
                Behavior
              </th>
              <th className='p-2.5 pt-1 pb-1 pr-9 text-center font-medium sm:p-4 sm:pr-14'>
                Total
              </th>
            </tr>
          </thead>
          <tbody className='bg-white text-brandText'>
            {data
              ?.sort(function (a, b) {
                if (a.total === Infinity) return 1;
                else if (isNaN(a.total)) return -1;
                else return a.total - b.total;
              })
              .reverse()
              .map((item, itemIdx) => (
                <tr
                  key={itemIdx}
                  className={itemIdx % 2 === 0 ? undefined : 'bg-slate-50'}
                >
                  <td className='p-4 py-4 text-sm font-normal text-brandText duration-300 ease-in-out hover:scale-105 hover:text-brandPrimaryLight sm:pl-14'>
                    <button
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      {item?.student_name}
                    </button>
                  </td>
                  <td className='mx-auto p-2.5 py-4 font-medium sm:p-4'>
                    <div
                      className={`mx-auto flex h-3 w-3 rounded-full sm:h-4 sm:w-4 ${elaCalc(
                        item?.ela,
                      )}`}
                    ></div>
                  </td>
                  <td className='mx-auto p-2.5 py-4 text-center font-medium sm:p-4'>
                    <div
                      className={`mx-auto flex h-3 w-3 rounded-full sm:h-4 sm:w-4 ${mathCalc(
                        item?.math,
                      )}`}
                    ></div>
                  </td>
                  <td className='mx-auto p-2.5 py-4 text-center font-medium sm:p-4'>
                    <div
                      className={`mx-auto flex h-3 w-3 rounded-full sm:h-4 sm:w-4 ${attendanceCalc(
                        item?.attendance,
                      )}`}
                    ></div>
                  </td>
                  <td className='mx-auto p-2.5 py-4 text-center font-medium sm:p-4'>
                    <div
                      className={`mx-auto flex h-3 w-3 rounded-full sm:h-4 sm:w-4 ${behavioralCalc(
                        item?.behavior,
                      )}`}
                    ></div>
                  </td>
                  <td className='mx-auto p-2.5 py-4 pr-9 text-center font-medium sm:p-4 sm:pr-14'>
                    <div
                      className={`mx-auto flex h-3 w-3 rounded-full sm:h-4 sm:w-4 ${totalCalc(
                        item?.total,
                      )}`}
                    ></div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={data} />
      </div>
      <AtAGlanceModal open={open} setOpen={setOpen} id={id} />
    </div>
  );
}
