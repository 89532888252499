import recentStateScoreIcon from '../../images/circle_icons/recent_state_score.svg';

export default function MathRecentTable({ item }) {
  return (
    <div className='flex w-full flex-row space-x-8 text-brandText'>
      <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img
                className='h-12 w-12 sm:h-14 sm:w-14'
                src={recentStateScoreIcon}
                alt='Grades'
              />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>Recent</h2>
            <p className='ml-2 py-1 text-sm'>Score</p>
          </div>
          <div>
            <p className='ml-5 text-6xl font-bold text-brandPrimaryLight'>
              {item?.scale_score ? item?.scale_score : ''}
            </p>
          </div>
        </div>
        <div className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          <p className='ml-10 text-base font-medium md:text-2xl xl:text-xl'>
            {item ? 'Grade' : ''}{' '}
            {item?.grade_level_iar ? item?.grade_level_iar : ''}
            {item ? ',' : ''}{' '}
            {item?.test_year?.end_year ? item?.test_year?.end_year : ''}{' '}
            {item ? 'Level' : ''}{' '}
            {item?.performance_level ? item?.performance_level : ''}&nbsp;
          </p>
        </div>
        {item ? (
          <div className='mx-12 mt-7 flex flex-col text-sm sm:text-base'>
            <h2 className='text-base font-medium sm:text-lg'>Subscores</h2>
            <div className='mt-1 flex flex-row justify-between'>
              <p>Major Content</p>
              <p>
                {item?.math_subclaim_major_content
                  ? item?.math_subclaim_major_content
                  : ''}
              </p>
            </div>
            <div className='mt-1 flex flex-row justify-between'>
              <p>Expressing Math Reasoning</p>
              <p>
                {item?.math_subclaim_expressing_mathematical_reasoning
                  ? item?.math_subclaim_expressing_mathematical_reasoning
                  : ''}
              </p>
            </div>
            <div className='mt-1 flex flex-row justify-between'>
              <p>Modeling & Applications</p>
              <p>
                {item?.math_subclaim_modeling_and_application
                  ? item?.math_subclaim_modeling_and_application
                  : ''}
              </p>
            </div>
            <div className='mt-1 flex flex-row justify-between'>
              <p>Supporting & Additional Content</p>
              <p>
                {item?.math_subclaim_addtional_and_supoorting_content
                  ? item?.math_subclaim_addtional_and_supoorting_content
                  : ''}
              </p>
            </div>
          </div>
        ) : (
          <div className='mx-10 mt-7 flex flex-col'>
            <h2 className='text-lg font-medium'>No Records</h2>
          </div>
        )}
      </div>
    </div>
  );
}
