import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import blueGearIcon from '../images/shortcut_icons/blue_gear_icon.png';
import whiteGearIcon from '../images/shortcut_icons/white_gear_icon.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MyShortcuts({ data }) {
  return (
    <Popover className='relative z-50'>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open
                ? 'border-t-4 border-gray-100 bg-white text-brandPrimary'
                : 'bg-brandPrimaryLight text-white',
              'flex h-14 w-64 flex-row items-center justify-between text-lg font-bold focus:outline-none',
            )}
          >
            <div className='flex w-full flex-row items-center justify-between duration-300 ease-in-out hover:scale-110'>
              <div className='ml-4 flex h-8 w-8'></div>
              <div className='mx-3 text-base font-bold'>My Shortcuts</div>
              <div className='mr-4 h-8 w-8'>
                {/* <CogIcon className='mr-4 h-10 w-10' /> */}
                {open ? (
                  <img className='h-8 w-8' src={blueGearIcon} />
                ) : (
                  <img className='h-8 w-8' src={whiteGearIcon} />
                )}
              </div>
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute left-[256px] z-50 -mt-[56px] w-screen max-w-xs px-2 sm:px-0'>
              <div className='border-t-5 overflow-hidden rounded-r-md border-gray-800 border-opacity-10 shadow-xl'>
                <nav className='relative bg-brandPrimary shadow-xl'>
                  {data?.map((item, itemIdx) => (
                    <Link key={itemIdx} to={item?.href}>
                      <div
                        className={`${
                          item?.current
                            ? ' bg-white text-[#0a9BBE] '
                            : ' border-b-4 border-r-4 border-r-gray-800 bg-[#0A9BBE] text-white duration-300 ease-in-out hover:scale-110 hover:border-b-0 hover:bg-white hover:text-[#0A9BBe] '
                        } flex h-14 flex-row items-center border-b-gray-800 border-opacity-10`}
                      >
                        <div>
                          <img src={item?.icon} className='ml-8 h-10 w-10' />
                        </div>
                        <div className='flex w-full flex-row justify-center text-center text-lg font-bold'>
                          {item?.name}
                        </div>
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
