import FormSpinner from '../../formComponents/FormSpinner';

export default function ReferralDetail({ loading, referralDetails }) {
  return (
    <>
      {loading ? (
        <FormSpinner loading={loading} />
      ) : (
        <div className='mt-12 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
          <div className='overflow-hidden bg-white shadow-lg ring-1 ring-gray-100 sm:rounded-lg'>
            <div className='bg-brandPrimary px-4 py-5 text-white sm:px-6'>
              <h3 className='text-lg font-bold leading-6'>
                {referralDetails?.student?.first_name}{' '}
                {referralDetails?.student?.last_name}
              </h3>
            </div>
            <div className='border-t border-gray-100 px-4 py-5 sm:p-0'>
              <dl className='sm:divide-y sm:divide-gray-100'>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Created by:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.author?.first_name}{' '}
                    {referralDetails?.author?.last_name}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>Issue:</dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.issue}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Teacher Actions:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.teacher_actions}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Category:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.category?.map((item, index) => (
                      <span key={index}>
                        {(index ? ', ' : '') + item?.category_name}
                      </span>
                    ))}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>Subject:</dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.subject?.map((item, index) => (
                      <span key={index}>
                        {(index ? ', ' : '') + item?.subject_name}
                      </span>
                    ))}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Academic Concerns:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.academic?.map((item, index) => (
                      <span key={index}>
                        {(index ? ', ' : '') + item?.concern_name}
                      </span>
                    ))}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Academic Comment:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.academic_comment}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Behavioral Concerns
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.behavioral?.map((item, index) => (
                      <span key={index}>
                        {(index ? ', ' : '') + item?.concern_name}
                      </span>
                    ))}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Behavioral Comment:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.behavioral_comment}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Attendance is a factor in this issue?
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.attendance ? 'Yes' : 'No'}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Have the parents/guardians been contacted about this issue?
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.parent ? 'Yes' : 'No'}
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-bold text-brandText'>
                    Dates and Outcomes:
                  </dt>
                  <dd className='mt-1 text-sm text-brandText sm:col-span-2 sm:mt-0'>
                    {referralDetails?.parent_comment}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
