import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import StudentsTable from '../components/sections/Dashboard/StudentsTable';
import StudentSliderSort from '../components/sections/Dashboard/StudentSliderSort';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function StudentsISupervise() {
  let { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [students, setStudents] = useState([]);

  /* state variables for sorting component */
  const [elaMin, setElaMin] = useState(0);
  const [elaMax, setElaMax] = useState(99);
  const [mathMin, setMathMin] = useState(0);
  const [mathMax, setMathMax] = useState(99);
  const [attendanceMin, setAttendanceMin] = useState(0);
  const [attendanceMax, setAttendanceMax] = useState(50);
  const [referralsMin, setReferralsMin] = useState(0);
  const [referralsMax, setReferralsMax] = useState(20);
  const [totalMin, setTotalMin] = useState(0);
  const [totalMax, setTotalMax] = useState(8);
  const [sortedStudents, setSortedStudents] = useState();

  useEffect(() => {
    getStudents();
    postPageView(baseUrl, user.name, user.role, 'students');
  }, []);

  let getStudents = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/teacher-students/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudents(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  const totalCalc = (ela, math, att, beh) => {
    let total = 0;
    if (ela === null) {
      total += 0;
    } else if (ela < 16) {
      total += 2;
    } else if (ela < 26) {
      total += 1;
    }
    if (math === null) {
      total += 0;
    } else if (math < 16) {
      total += 2;
    } else if (math < 26) {
      total += 1;
    }
    if (att === null) {
      total += 0;
    } else if (att > 10) {
      total += 2;
    } else if (att > 5) {
      total += 1;
    }
    if (beh === null) {
      total += 0;
    } else if (beh > 3) {
      total += 2;
    } else if (beh > 0) {
      total += 1;
    }
    return total;
  };

  useEffect(() => {
    students.forEach((object) => {
      object['total'] = totalCalc(
        object.ela,
        object.math,
        object.attendance,
        object.behavior,
      );
    });
  }, [students]);

  useEffect(() => {
    setSortedStudents(
      students.filter(
        (student) =>
          (student.ela >= elaMin || student.ela === null) &&
          (student.ela <= elaMax || student.ela === null) &&
          (student.math >= mathMin || student.math === null) &&
          (student.math <= mathMax || student.math === null) &&
          (student.attendance >= attendanceMin ||
            student.attendance === null) &&
          (student.attendance <= attendanceMax ||
            student.attendance === null) &&
          (student.behavior >= referralsMin || student.behavior === null) &&
          (student.behavior <= referralsMax || student.behavior === null) &&
          (student.total >= totalMin || student.total === null) &&
          (student.total <= totalMax || student.total === null),
      ),
    );
  }, [
    students,
    elaMin,
    elaMax,
    mathMin,
    mathMax,
    attendanceMin,
    attendanceMax,
    referralsMin,
    referralsMax,
    totalMin,
    totalMax,
  ]);

  return (
    <AccessWrapper allowedGroups={['teacher']}>
      <div>
        <SidebarLayout
          propComponents={
            <div className='px-4 pb-20  sm:px-6 lg:px-8'>
              <div className='mt-8 md:mt-16'>
                <StudentsTable loading={loading} data={sortedStudents} />
              </div>
            </div>
          }
          sortComponent={
            <div>
              <StudentSliderSort
                elaMin={elaMin}
                setElaMin={setElaMin}
                elaMax={elaMax}
                setElaMax={setElaMax}
                mathMin={mathMin}
                setMathMin={setMathMin}
                mathMax={mathMax}
                setMathMax={setMathMax}
                attendanceMin={attendanceMin}
                setAttendanceMin={setAttendanceMin}
                attendanceMax={attendanceMax}
                setAttendanceMax={setAttendanceMax}
                referralsMin={referralsMin}
                setReferralsMin={setReferralsMin}
                referralsMax={referralsMax}
                setReferralsMax={setReferralsMax}
                totalMin={totalMin}
                setTotalMin={setTotalMin}
                totalMax={totalMax}
                setTotalMax={setTotalMax}
              />
            </div>
          }
          heading={'Students'}
          studentsCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
