import scoresIcon from '../../images/circle_icons/scores.svg';

export default function ReadingScoreTable({ data }) {
  return (
    <div className='flex w-full flex-row space-x-8 text-brandText'>
      <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img
                className='h-12 w-12 sm:h-14 sm:w-14'
                src={scoresIcon}
                alt='Grades'
              />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>Score</h2>
            <p className='ml-2 text-sm'>History</p>
          </div>
        </div>
        <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          {data?.length >= 1 ? (
            <thead>
              <tr className='text-base'>
                <th className='p-4 pl-9 pt-1 pb-1 text-left text-base font-medium sm:pl-14 md:text-2xl xl:text-xl'>
                  Date
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl xl:text-xl'>
                  Scale&nbsp;Score
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl xl:text-xl'>
                  Percentile
                </th>
                <th className='p-4 pt-1 pr-9 pb-1 text-left text-base font-medium sm:pr-14 md:text-2xl xl:text-xl'>
                  NCE
                </th>
              </tr>
            </thead>
          ) : (
            <thead>
              <tr className='py-1 text-xl'>
                <td>&nbsp;</td>
              </tr>
            </thead>
          )}
          <tbody className='bg-white text-brandText'>
            {data?.length >= 1 &&
              data?.map((item) => (
                <tr key={item?.id}>
                  <td className='p-4 py-2 pl-9 font-medium sm:pl-14'>
                    {item?.test_date}
                  </td>
                  <td className='p-4 py-2 font-medium'>
                    {item?.unified_scaled_score}
                  </td>
                  <td className='p-4 py-2 font-medium'>{item?.percentile}</td>
                  <td className='p-4 py-2 pr-9 font-medium sm:pr-14'>
                    {item?.nce}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {data?.length === 0 && (
          <div className='ml-10 mt-7 text-lg font-medium'>No Records</div>
        )}
      </div>
    </div>
  );
}
