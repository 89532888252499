import React, { useState, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../formComponents/TextInput';
import NumberInput from '../../formComponents/NumberInput';
import Notification from '../../formComponents/Notification';

export default function AssessmentsForm() {
  const { authTokens, baseUrl, user } = useContext(AuthContext);
  const [assessmentName, setAssessmentName] = useState('');
  const [score1Name, setScore1Name] = useState('');
  const [score1Success, setScore1Success] = useState(null);
  const [score1Warning, setScore1Warning] = useState(null);
  const [score1Danger, setScore1Danger] = useState(null);
  const [score2Name, setScore2Name] = useState('');
  const [show, setShow] = useState(false);
  const [assessmentNameLabelColor, setAssessmentNameLabelColor] =
    useState('text-brandText');
  const [assessmentNameBorderColor, setAssessmentNameBorderColor] =
    useState('border-gray-300');
  const [primaryScoreTitleLabelColor, setPrimaryScoreTitleLabelColor] =
    useState('text-brandText');
  const [primaryScoreTitleBorderColor, setPrimaryScoreTitleBorderColor] =
    useState('border-gray-300');
  const [
    minimalScoreForSuccessLabelColor,
    setMinimalScoreForSuccessLabelColor,
  ] = useState('text-brandText');
  const [
    minimalScoreForSuccessBorderColor,
    setMinimalScoreForSuccessBorderColor,
  ] = useState('border-gray-300');
  const [
    minimalScoreForWarningLabelColor,
    setMinimalScoreForWarningLabelColor,
  ] = useState('text-brandText');
  const [
    minimalScoreForWarningBorderColor,
    setMinimalScoreForWarningBorderColor,
  ] = useState('border-gray-300');
  const [failureScoreAtOrBelowLabelColor, setFailureScoreAtOrBelowLabelColor] =
    useState('text-brandText');
  const [
    failureScoreAtOrBelowBorderColor,
    setFailureScoreAtOrBelowBorderColor,
  ] = useState('border-gray-300');
  const [alternateScoreTitleLabelColor, setAlternateScoreTitleLabelColor] =
    useState('text-brandText');
  const [alternateScoreTitleBorderColor, setAlternateScoreTitleBorderColor] =
    useState('border-gray-300');

  const validateFormData = (e) => {
    e.preventDefault();
    if (
      assessmentName?.trim().length >= 1 &&
      score1Name?.trim().length >= 1 &&
      score1Success?.match(/^[0-9]+$/) != null &&
      score1Warning?.match(/^[0-9]+$/) != null &&
      score1Danger?.match(/^[0-9]+$/) != null &&
      score2Name?.trim().length >= 1
    ) {
      setAssessmentNameLabelColor('text-brandText');
      setAssessmentNameBorderColor('border-gray-300');
      setPrimaryScoreTitleLabelColor('text-brandText');
      setPrimaryScoreTitleBorderColor('border-gray-300');
      setMinimalScoreForSuccessLabelColor('text-brandText');
      setMinimalScoreForSuccessBorderColor('border-gray-300');
      setMinimalScoreForWarningLabelColor('text-brandText');
      setMinimalScoreForWarningBorderColor('border-gray-300');
      setFailureScoreAtOrBelowLabelColor('text-brandText');
      setFailureScoreAtOrBelowBorderColor('border-gray-300');
      setAlternateScoreTitleLabelColor('text-brandText');
      setAlternateScoreTitleBorderColor('border-gray-300');
      handleSubmit(e);
    } else {
      if (assessmentName?.trim().length >= 1) {
        setAssessmentNameLabelColor('text-brandText');
        setAssessmentNameBorderColor('border-gray-300');
      } else {
        setAssessmentNameLabelColor('text-red-500');
        setAssessmentNameBorderColor('border-red-500');
      }

      if (score1Name?.trim().length >= 1) {
        setPrimaryScoreTitleLabelColor('text-brandText');
        setPrimaryScoreTitleBorderColor('border-gray-300');
      } else {
        setPrimaryScoreTitleLabelColor('text-red-500');
        setPrimaryScoreTitleBorderColor('border-red-500');
      }

      if (score1Success?.match(/^[0-9]+$/) != null) {
        setMinimalScoreForSuccessLabelColor('text-brandText');
        setMinimalScoreForSuccessBorderColor('border-gray-300');
      } else {
        setMinimalScoreForSuccessLabelColor('text-red-500');
        setMinimalScoreForSuccessBorderColor('border-red-500');
      }

      if (score1Warning?.match(/^[0-9]+$/) != null) {
        setMinimalScoreForWarningLabelColor('text-brandText');
        setMinimalScoreForWarningBorderColor('border-gray-300');
      } else {
        setMinimalScoreForWarningLabelColor('text-red-500');
        setMinimalScoreForWarningBorderColor('border-red-500');
      }

      if (score1Danger?.match(/^[0-9]+$/) != null) {
        setFailureScoreAtOrBelowLabelColor('text-brandText');
        setFailureScoreAtOrBelowBorderColor('border-gray-300');
      } else {
        setFailureScoreAtOrBelowLabelColor('text-red-500');
        setFailureScoreAtOrBelowBorderColor('border-red-500');
      }

      if (score2Name?.trim().length >= 1) {
        setAlternateScoreTitleLabelColor('text-brandText');
        setAlternateScoreTitleBorderColor('border-gray-300');
      } else {
        setAlternateScoreTitleLabelColor('text-red-500');
        setAlternateScoreTitleBorderColor('border-red-500');
      }

      setShow(true);
    }
  };

  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(`${baseUrl}/mtss/progress-assessments/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          assessment_name: assessmentName,
          score1_name: score1Name,
          score1_success: score1Success,
          score1_warning: score1Warning,
          score1_danger: score1Danger,
          score2_name: score2Name,
        }),
      });

      let data = await response.json();

      if (response.status === 201) {
        navigate(-1);
      } else {
        setShow(true);
        console.log(data);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/add-assessment',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to add the new assessment and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/add-assessment',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to add the new assessment and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  return (
    <div>
      <form
        className='mx-auto flex max-w-3xl flex-col space-y-8 px-4 py-12 sm:px-6 sm:py-16 lg:px-8 lg:py-20'
        action=''
      >
        <TextInput
          name={'assessment_name'}
          displayName={'Assessment Name*'}
          data={assessmentName}
          setData={setAssessmentName}
          labelColor={assessmentNameLabelColor}
          borderColor={assessmentNameBorderColor}
        />
        <TextInput
          name={'Primary Score Title'}
          displayName={'Primary Score Title*'}
          data={score1Name}
          setData={setScore1Name}
          labelColor={primaryScoreTitleLabelColor}
          borderColor={primaryScoreTitleBorderColor}
        />
        <NumberInput
          name={'Minimum Score for Success'}
          label={'Minimum Score for Success*'}
          data={score1Success}
          setData={setScore1Success}
          labelColor={minimalScoreForSuccessLabelColor}
          borderColor={minimalScoreForSuccessBorderColor}
        />
        <NumberInput
          name={'Minimum Score for Warning'}
          label={'Minimum Score for Warning*'}
          data={score1Warning}
          setData={setScore1Warning}
          labelColor={minimalScoreForWarningLabelColor}
          borderColor={minimalScoreForWarningBorderColor}
        />
        <NumberInput
          name={'Failure Score at or Below'}
          label={'Failure Score at or Below*'}
          data={score1Danger}
          setData={setScore1Danger}
          labelColor={failureScoreAtOrBelowLabelColor}
          borderColor={failureScoreAtOrBelowBorderColor}
        />
        <TextInput
          name={'Alternate Score Title'}
          displayName={'Alternate Score Title*'}
          data={score2Name}
          setData={setScore2Name}
          labelColor={alternateScoreTitleLabelColor}
          borderColor={alternateScoreTitleBorderColor}
        />
        <button
          onClick={validateFormData}
          className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Submit
        </button>
      </form>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been completed.'
        }
      />
    </div>
  );
}
