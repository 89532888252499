import StudentSidebarLayout from '../../components/StudentSidebarLayout';
import StudentPageTopper from '../../components/StudentPageTopper';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import ReadingScoreTable from '../../components/student_components/ReadingScoreTable';
import ReadingRecentTable from '../../components/student_components/ReadingRecentTable';
/* import ReadingScatterPlot from '../../components/student_components/ReadingScatterPlot'; */
import RechartsScatter from '../../components/student_components/RechartsScatter';
import { Oval } from 'react-loader-spinner';
import AccessWrapper from '../../components/AccessWrapper';

export default function Reading() {
  const { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.studentId;

  /* state variable to hold selected student */
  const [student, setStudent] = useState();
  const [studentScores, setStudentScores] = useState([]);
  const [scatterData, setScatterData] = useState(undefined);
  const [studentAssessment, setStudentAssessment] = useState();

  useEffect(() => {
    getStudent();
    getStudentScores();
    getStudentAssessment();
    postPageView(baseUrl, user.name, user.role, 'student/reading');
  }, []);

  let getStudent = async () => {
    let response = await fetch(`${baseUrl}/sis/students/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudent(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentScores = async () => {
    let response = await fetch(
      `${baseUrl}/assessment/star/?student=${id}&subject=reading`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentScores(data);
      setScatterData(data.reverse());
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentAssessment = async () => {
    let response = await fetch(
      `${baseUrl}/assessment/iar/?student=${id}&subject=ELA/L`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentAssessment(data[0]);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <StudentSidebarLayout
          propComponents={
            <div>
              <StudentPageTopper student={student} />
              <div className='relative -top-14 mx-auto'>
                <div className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl'>
                    <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                      Reading Performance
                    </h1>
                    {scatterData !== undefined ? (
                      <div className='mx-auto'>
                        {scatterData?.length === 0 ? (
                          <div className='mx-auto flex h-80 w-full max-w-7xl flex-row items-center justify-center rounded-2xl p-10'>
                            <p className='text-4xl font-normal text-gray-200'>
                              No Records
                            </p>
                          </div>
                        ) : (
                          <div className='mx-auto'>
                            <div className='mx-auto max-w-7xl'>
                              <RechartsScatter data={scatterData} />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='mx-auto flex h-96 w-full max-w-7xl flex-row items-center justify-center rounded-2xl p-10 md:h-[450px] lg:h-[600px]'>
                        <Oval
                          height={80}
                          width={80}
                          color='#0CB2DA'
                          wrapperStyle={{}}
                          wrapperClass=''
                          visible={true}
                          ariaLabel='oval-loading'
                          secondaryColor='#e5e7eb'
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='mx-auto mt-10 bg-gray-100 px-4 pt-1 pb-12 sm:px-6 sm:pb-16 lg:px-8 lg:pb-20'>
                  <div className='mx-auto mt-6 flex w-full max-w-7xl flex-col xl:flex-row xl:space-x-8'>
                    <div className='flex w-full flex-col items-center'>
                      <h3 className='mb-5 text-2xl font-normal text-brandPrimary sm:mb-7 sm:text-3xl'>
                        Score History
                      </h3>
                      <ReadingScoreTable data={studentScores} />
                    </div>
                    <div className='mt-5 flex h-full w-full flex-col items-center sm:mt-7 xl:mt-0'>
                      <h3 className='mb-5 text-2xl font-normal text-brandPrimary sm:mb-7 sm:text-3xl'>
                        State Assessment
                      </h3>
                      <ReadingRecentTable item={studentAssessment} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          heading={'Student Report'}
          id={id}
          heading2={'Reading'}
          readingCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
