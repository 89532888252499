import { useNavigate } from 'react-router-dom';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function ActionDetailsChecksTable({ loading, data }) {
  const navigate = useNavigate();

  const navigateToForm = (item) => {
    navigate(`/mtss/check-update/${item?.id}`);
  };

  return (
    <div className='-mx-4 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:rounded-lg'>
      <h2 className='bg-brandPrimary pl-4 pt-5 text-2xl font-bold text-white sm:pl-6'>
        Check-Ins for this Action
      </h2>
      <table className='min-w-full divide-y divide-gray-100'>
        <thead className='bg-brandPrimary'>
          <tr>
            <th
              scope='col'
              className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
            >
              Date
            </th>
            <th
              scope='col'
              className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
            >
              Author
            </th>
            <th
              scope='col'
              className='hidden px-3 py-3.5 text-left text-lg font-bold text-white sm:pr-6 lg:table-cell'
            >
              Assessment Name
            </th>
            <th
              scope='col'
              className='hidden px-3 py-3.5 text-left text-lg font-bold text-white sm:pr-6 lg:table-cell'
            >
              Assessment Score
            </th>
            <th
              scope='col'
              className='px-3 py-3.5 pr-4 text-left text-lg font-bold text-white sm:pr-6'
            >
              Comment
            </th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-100 bg-white'>
          {data?.map((item) => (
            <tr key={item?.id}>
              <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                <button
                  className='rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110 hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                  onClick={() => {
                    navigateToForm(item);
                  }}
                >
                  <span className='font-bold text-brandText lg:hidden'>
                    Date:{' '}
                  </span>
                  {item?.created_at}
                </button>
                <dl className='font-normal lg:hidden'>
                  <dt className='sr-only'>Teacher</dt>
                  <dd className='mt-1 truncate'>
                    <span className='font-bold'>Teacher: </span>
                    {item?.user?.first_name} {item?.user?.last_name}
                  </dd>
                  <dt className='sr-only'>Assessment Name</dt>
                  <dd className='mt-1 truncate'>
                    <span className='font-bold'>Assessment Name: </span>
                    {item?.progress_assessment?.assessment_name}
                  </dd>
                  <dt className='sr-only'>Assessment Score</dt>
                  <dd className='mt-1 truncate'>
                    <span className='font-bold'>Assessment Score: </span>
                    {item?.assessment_score}
                  </dd>
                </dl>
              </td>

              <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                {item?.user?.first_name} {item?.user?.last_name}
              </td>
              <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                {item?.progress_assessment?.assessment_name}
              </td>
              <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                {item?.assessment_score}
              </td>
              <td className='px-3 py-4 text-sm font-normal text-brandText'>
                <dd>{item?.comment_text}</dd>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <FormSpinner loading={loading} />
      <NoRecords loading={loading} data={data} />
    </div>
  );
}
