import { useState, useEffect, useContext } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function HomeSearch({}) {
  const [query, setQuery] = useState('');

  let { authTokens, logoutUser, baseUrl } = useContext(AuthContext);

  const [filteredPeople, setFilteredPeople] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState('');

  const navigate = useNavigate();

  const navigateToStudent = (item) => {
    navigateToStudent(`/students/at-a-glance/${item}`);
  };

  /* useEffect(() => {
    getStudents();
  }, []); */

  let getStudents = async () => {
    let response = await fetch(
      `${baseUrl}/sis/students/?search=${query}&enrolled=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens?.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setFilteredPeople(data.results);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
    if (response.status === 401) {
      logoutUser();
    }
  };

  useEffect(() => {
    getStudents();
  }, [query]);

  /* const filteredPeople =
    query === ''
      ? people
      : people?.filter((person) => {
          return person?.last_name.toLowerCase().includes(query.toLowerCase());
        }); */

  return (
    <Combobox
      className={''}
      as='div'
      value={selectedPerson}
      onChange={setSelectedPerson}
    >
      {/* <Combobox.Label className='block text-sm font-medium text-brandText'>
        Student
      </Combobox.Label> */}
      <div className='relative mt-1'>
        <Combobox.Input
          className='w-full rounded-md border-none bg-white py-3 pl-3 pr-10 text-brandText focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
          onChange={(e) => setQuery(e.target.value)}
          onKeyUp={(e) =>
            e.key === 'Enter' &&
            navigate(`/students/at-a-glance/${selectedPerson?.id}`)
          }
          /* displayValue={(person) =>
            person
              ? person?.first_name + ' ' + person?.last_name
              : 'Search Students'
          } */
          placeholder='Search Students'
        />
        {/* <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </Combobox.Button> */}

        {filteredPeople?.length > 0 && (
          <Combobox.Options className='absolute z-10 mt-1 h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredPeople?.map((person) => (
              <Link key={person?.id} to={`/students/at-a-glance/${person?.id}`}>
                <Combobox.Option
                  value={person}
                  onKeyPress={(e) => {
                    if (e.nativeEvent.charCode === 13) {
                      navigate(`/students/at-a-glance/${person?.id}`);
                    }
                  }}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-brandPrimary text-white' : 'text-brandText',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          'block truncate',
                          selected && 'font-semibold',
                        )}
                      >
                        {person?.first_name + ' ' + person?.last_name}
                      </span>
                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-brandPrimary',
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              </Link>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
