import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavDropdown({ logoutUser, user }) {
  return (
    <Menu
      as='div'
      className='relative inline-block text-left outline-none ring-0'
    >
      <div>
        <Menu.Button className='inline-flex w-full items-center justify-center rounded-md border-2 border-transparent bg-brandPrimary py-1.5 px-2.5 text-sm font-medium text-white shadow-sm outline-none ring-0 duration-300 ease-in-out focus:outline-none focus:ring-0 lg:hover:scale-110 lg:hover:bg-brandPrimaryLight'>
          Hello {user?.name?.split(', ').slice(-1).join(' ')}!
          <ChevronDownIcon className='-mr-1 ml-1 h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-2 ring-brandText ring-opacity-5 focus:outline-none'>
          <div className='px-4 py-3 text-brandText'>
            <p className='text-sm font-medium'>Signed in as</p>
            <p className='truncate text-sm font-medium text-brandText'>
              {user?.email}
            </p>
          </div>
          <div className='overflow-hidden rounded-b-md'>
            <Menu.Item>
              {({ active }) => (
                <a
                  href='https://www.polaris-edu.com/resources'
                  className={classNames(
                    active
                      ? 'scale-110 bg-brandPrimary pl-7 text-white duration-300 ease-in-out'
                      : 'text-brandText',
                    'block px-4 pb-3 pt-4 text-sm font-medium',
                  )}
                >
                  Resources
                </a>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <Link
                  to='/password-reset'
                  className={classNames(
                    active
                      ? 'scale-110 bg-brandPrimary pl-7 text-white duration-300 ease-in-out'
                      : 'text-brandText',
                    'block px-4 py-3 text-sm font-medium',
                  )}
                >
                  Reset Password
                </Link>
              )}
            </Menu.Item> */}
            <form>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      logoutUser();
                    }}
                    className={classNames(
                      active
                        ? 'scale-110 bg-brandPrimary pl-7 text-white duration-300 ease-in-out'
                        : 'text-brandText',
                      'block w-full px-4 pt-3 pb-4 text-left text-sm font-medium',
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
