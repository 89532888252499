import AuthContext from '../context/AuthContext';
import { useContext } from 'react';
import Unauthorized from './Unauthorized';

export default function AccessWrapper({ children, allowedGroups }) {
  const { user } = useContext(AuthContext);
  return (
    <>
      {
        /*allowedGroups.includes(user?.role)*/ allowedGroups.some(
          (r) => user?.groups.indexOf(r) >= 0,
        ) ? (
          children
        ) : (
          <Unauthorized />
        )
      }
    </>
  );
}
