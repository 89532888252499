export const ContentContainer = ({ children, classArray }) => {

    const CreateContainer = (children) => {
        const defaultClasses = ['flex-1', 
            'bg-white', 
            'shadow-md', 
            'overflow-hidden', 
            'rounded-lg', 
            'border-2', 
            'border-l-slate-100', 
            'border-r-slate-100', 
            'border-b-slate-100', 
            'border-t-slate-50', 
            'pt-5', 
            'pb-8'
        ];

        const updateClasses = defaultClasses.concat(classArray);

        return <div className={updateClasses.join(' ')}>{children}</div>
    };

    return (
        <>
            {CreateContainer(children)}
        </>   
    ); 
};
