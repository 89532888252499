export default function GradesSortButton({
  id,
  item,
  setActiveButton,
  active,
  handleClick,
}) {
  const clickHandler = () => {
    setActiveButton(id);
  };
  return (
    <div className='flex flex-row justify-center'>
      <button
        onClick={() => {
          handleClick(item);
          clickHandler();
        }}
      >
        <p
          className={`${
            active
              ? ' bg-brandText text-white '
              : ' bg-slate-100 text-brandText '
          }w-auto mb-3 rounded-r-full rounded-l-full px-4 py-3 text-center text-xs font-semibold sm:px-6 sm:text-sm md:font-medium xl:mb-0 xl:px-12`}
        >
          {parseInt(item) - 1}-{item}
        </p>
      </button>
    </div>
  );
}
