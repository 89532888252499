import SidebarLayout from '../components/SidebarLayout';
import Cards from '../components/Cards';
import Card from '../components/Card';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import ReferralsCheckinsActions from '../components/sections/Dashboard/ReferralsCheckinsActions';
import StudentsTable from '../components/sections/Dashboard/StudentsTable';
import SupervisorCard from '../components/sections/Dashboard/SupervisorCard';
import CaregiverCard from '../components/sections/Dashboard/CaregiverCard';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

/* Dashboard Tiles */
import notesReferralsCard from '../images/dashboard_cards/notes_and_referrals.webp';
import actionsChecksCard from '../images/dashboard_cards/actions_and_checks.webp';
import studentsCard from '../images/dashboard_cards/students.webp';
import caregiverActionsCard from '../images/dashboard_cards/caregiver_actions.webp';
import studentsSuperviseCard from '../images/dashboard_cards/students_supervise.webp';
import caregiverReportsCard from '../images/dashboard_cards/caregiver_reports.webp';
import mtssCard from '../images/dashboard_cards/mtss.png';
import standardsCard from '../images/standards_tiles/standards_tile.png';

/* Dashboard Icons */
import notesAndReferralsIcon from '../images/dashboard_icons/notes_and_referrals.svg';
import actionsAndChecksIcon from '../images/dashboard_icons/actions_and_checks.svg';
import studentsIcon from '../images/dashboard_icons/students.svg';
import caregiverActionsIcon from '../images/dashboard_icons/caregiver_actions.svg';
import studentsISuperviseIcon from '../images/dashboard_icons/students_i_supervise.svg';
import caregiverReportsIcon from '../images/dashboard_icons/caregiver_reports.svg';
import mtssIcon from '../images/dashboard_icons/mtss.svg';
import standardsIcon from '../images/standards_icons/standards_icon.svg';

export default function Dashboard() {
  const { user, authTokens, baseUrl } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [userData, setUserData] = useState(null);
  const [displayStudents, setDisplayStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    user?.groups?.includes('teacher') && getStudents();
    getUserData();
  }, [user?.groups]);

  let getStudents = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/teacher-students/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudents(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  let getUserData = async () => {
    let response = await fetch(`${baseUrl}/mtss/referrals-checkins-actions/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setUserData(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  const totalCalc = (ela, math, att, beh) => {
    let total = 0;
    if (ela === null) {
      total += 0;
    } else if (ela < 16) {
      total += 2;
    } else if (ela < 26) {
      total += 1;
    }
    if (math === null) {
      total += 0;
    } else if (math < 16) {
      total += 2;
    } else if (math < 26) {
      total += 1;
    }
    if (att === null) {
      total += 0;
    } else if (att > 10) {
      total += 2;
    } else if (att > 5) {
      total += 1;
    }
    if (beh === null) {
      total += 0;
    } else if (beh > 3) {
      total += 2;
    } else if (beh > 0) {
      total += 1;
    }
    return total;
  };

  useEffect(() => {
    user?.role === 'Teacher' &&
      students?.forEach((object) => {
        object['total'] = totalCalc(
          object.ela,
          object.math,
          object.attendance,
          object.behavior,
        );
      });
    setDisplayStudents(students);
  }, [students]);

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='mb-12 sm:mb-16 lg:mb-20'>
              {user?.groups?.some((el) =>
                [
                  'teacher',
                  'mtss_member',
                  'mtss_supervisor',
                  'mtss_admin',
                ].includes(el),
              ) && (
                <div className='mx-auto my-12 px-4 sm:my-16 sm:px-6 lg:my-20 lg:px-8'>
                  <ReferralsCheckinsActions data={userData} user={user} />
                </div>
              )}
              {user?.groups?.includes('mtss_supervisor') && (
                <div className='mt-12 flex w-full flex-col px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8 xl:flex-row xl:space-x-8'>
                  <div className='flex w-full'>
                    <SupervisorCard />
                  </div>
                  <div className='flex w-full'>
                    <CaregiverCard />
                  </div>
                </div>
              )}
              <Cards
                cardProps={
                  <>
                    <>
                      {user?.groups?.includes('teacher') && (
                        <Card
                          cardImage={studentsCard}
                          href={'/students'}
                          cardName={'Students'}
                          cardIcon={studentsIcon}
                        />
                      )}

                      {(user?.groups?.includes('teacher') ||
                        user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={notesReferralsCard}
                          href={'/notes-and-referrals'}
                          cardName={'Notes & Referrals'}
                          cardIcon={notesAndReferralsIcon}
                        />
                      )}

                      {user?.groups?.includes('teacher') && (
                        <Card
                          cardImage={caregiverActionsCard}
                          href={'/mtss/caregiver-actions'}
                          cardName={'Caregiver Actions'}
                          cardIcon={caregiverActionsIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={actionsChecksCard}
                          href={'/actions-and-checks'}
                          cardName={'Actions & Checks'}
                          cardIcon={actionsAndChecksIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={studentsSuperviseCard}
                          href={'/mtss/students-i-supervise'}
                          cardName={'Students I Supervise'}
                          cardIcon={studentsISuperviseIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={caregiverReportsCard}
                          href={'/mtss/caregiver-reports'}
                          cardName={'Caregiver Reports'}
                          cardIcon={caregiverReportsIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin') ||
                        user?.groups?.includes('district_admin')) && (
                        <Card
                          cardImage={mtssCard}
                          href={'/mtss'}
                          cardName={'MTSS'}
                          cardIcon={mtssIcon}
                        />
                      )}

                      {/* {user?.groups?.includes('standards_admin') && (
                        <Card
                        cardImage={standardsCard}
                        href={'/standards'}
                        cardName={'Standards'}
                        cardIcon={standardsIcon}
                      />
                      )} */}
                    </>
                  </>
                }
              />
              {user?.groups?.includes('teacher') && (
                <div className='mx-auto mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
                  <StudentsTable loading={loading} data={displayStudents} />
                </div>
              )}
            </div>
          }
          heading={'Dashboard'}
          dashboardCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
