import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import TextBox from '../../formComponents/TextBox';
import DatePicker from '../../formComponents/DatePicker';
import Notification from '../../formComponents/Notification';
import ActionDetailsScatter from '../Dashboard/ActionDetailsScatter';
import { Oval } from 'react-loader-spinner';
import WarningModal from '../../WarningModal';
import TodaysDate from '../../../utils/TodaysDate';
import DisclaimerModal from '../../DisclaimerModal';
import { InformationCircleIcon } from '@heroicons/react/outline';

export default function ChecksUpdateForm({ id }) {
  const { authTokens, baseUrl } = useContext(AuthContext);
  const [check, setCheck] = useState(undefined);
  const [comment, setComment] = useState('');
  const [nextCheck, setNextCheck] = useState(undefined);
  const [show, setShow] = useState(false);
  const [student, setStudent] = useState(undefined);
  const [scatterData, setScatterData] = useState(undefined);
  const [assessment, setAssessment] = useState(undefined);
  const [assessmentPrimaryScore, setAssessmentPrimaryScore] =
    useState(undefined);
  const [assessmentAltScore, setAssessmentAltScore] = useState(undefined);
  const [assessmentComment, setAssessmentComment] = useState(undefined);
  const [messageOne, setMessageOne] = useState('');
  const [messageTwo, setMessageTwo] = useState('');
  const [selectedCheckAssessment, setSelectedCheckAssessment] = useState();
  const [showCheckWarning, setShowCheckWarning] = useState(false);
  const [assessmentDate, setAssessmentDate] = useState(TodaysDate());
  const [nextCheckLabelColor, setNextCheckLabelColor] =
    useState('textBrandText ');
  const [nextCheckBorderColor, setNextCheckBorderColor] =
    useState('border-gray-300');
  const [commentLabelColor, setCommentLabelColor] = useState('textBrandText ');
  const [commentBorderColor, setCommentBorderColor] =
    useState('border-gray-300');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [assessmentIsNullOrNone, setAssessmentIsNullOrNone] = useState(true);
  const [intervention, setIntervention] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    getCheckDetails();
  }, [id]);

  const validateCheck = (e) => {
    // checking to make sure that the comment is not empty and that the next check date is not null
    if (
      comment?.trim().length >= 1 &&
      nextCheck !== null &&
      nextCheck !== undefined &&
      nextCheck !== ''
    ) {
      handleCheck(e);
    } else {
      setMessageOne('Failed to add check-in!');
      setMessageTwo('Please ensure that all fields have been completed.');
      setShow(true);

      if (nextCheck !== null && nextCheck !== undefined && nextCheck !== '') {
        setNextCheckLabelColor('textBrandText ');
        setNextCheckBorderColor('border-gray-300');
      } else {
        setNextCheckLabelColor('text-red-500 ');
        setNextCheckBorderColor('border-red-500');
      }

      if (comment?.trim().length >= 1) {
        setCommentLabelColor('textBrandText ');
        setCommentBorderColor('border-gray-300');
      } else {
        setCommentLabelColor('text-red-500 ');
        setCommentBorderColor('border-red-500');
      }
    }
  };

  const getCheckDetails = async () => {
    try {
      const response = await fetch(`${baseUrl}/mtss/checks/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        setCheck(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let handleCheck = async (e) => {
    e.preventDefault();
    let response = await fetch(`${baseUrl}/mtss/checks/${id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
      body: JSON.stringify({
        intervention: intervention?.id,
        student: student,
        comment_text: comment,
        next_check: nextCheck,
        assessment_comment: assessmentComment,
        assessment_score: assessmentPrimaryScore,
        alt_assessment_score: assessmentAltScore,
        assessment_date: assessmentDate,
        progress_assessment: selectedCheckAssessment?.id,
      }),
    });

    if (response.status === 200) {
      navigate(-1);
    } else {
      setMessageOne('Failed to submit check-in!');
      setMessageTwo(
        'Please ensure that all fields and selections have been completed.',
      );
      setShow(true);
    }
  };

  let getScatterData = async () => {
    let response = await fetch(
      `${baseUrl}/mtss/check-scores/?intervention_id=${intervention?.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setScatterData(data?.reverse());
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  useEffect(() => {
    setStudent(check?.student.id);
    check?.next_check &&
      setNextCheck(check?.next_check?.replace(/(..).(..).(....)/, '$3-$1-$2'));
    check?.comment_text ? setComment(check?.comment_text) : setComment('');
    setAssessment(check?.progress_assessment);
    check?.assessment_date
      ? setAssessmentDate(check?.assessment_date)
      : setAssessmentDate(TodaysDate());
    check?.assessment_comment
      ? setAssessmentComment(check?.assessment_comment)
      : setAssessmentComment('');
    check?.assessment_score
      ? setAssessmentPrimaryScore(check?.assessment_score)
      : setAssessmentPrimaryScore('');
    check?.alt_assessment_score
      ? setAssessmentAltScore(check?.alt_assessment_score)
      : setAssessmentAltScore('');
    setIntervention(check?.intervention);
  }, [check]);

  useEffect(() => {
    if (
      assessment === null ||
      assessment === undefined ||
      assessment?.assessment_name === 'None'
    ) {
      setAssessmentIsNullOrNone(true);
    } else {
      setAssessmentIsNullOrNone(false);
      setSelectedCheckAssessment(assessment);
    }
  }, [assessment]);

  useEffect(() => {
    if (intervention?.id) {
      getScatterData();
    }
  }, [intervention]);

  return (
    <div className='mt-12 px-4 pb-20 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
      <div className='flex w-full flex-col justify-evenly space-y-8 overflow-hidden rounded-lg border-2 border-slate-200 bg-white py-8 px-4 shadow-md sm:px-6 lg:px-8'>
        {/* everything below this line is the same as the check in box on the intervention details page */}
        <div className='flex flex-row items-center justify-between'>
          <h2 className='text-2xl font-semibold text-brandPrimary'>Check In</h2>
          {assessmentIsNullOrNone && (
            <button
              onClick={() => {
                setShowDisclaimer(true);
              }}
              className='flex flex-row items-center space-x-1'
            >
              <p className='font-medium text-brandPrimary'>
                Progress Assessments
              </p>
              <InformationCircleIcon className='h-7 w-7 text-brandPrimary' />
            </button>
          )}
        </div>
        <div className='flex w-full flex-col space-x-8 xl:flex-row xl:items-center'>
          {!assessmentIsNullOrNone && (
            <div className='flex flex-col items-center justify-center xl:w-2/3'>
              <div className='mx-auto flex w-full items-center justify-center'>
                <h3 className='mb-5 text-2xl font-semibold text-brandPrimary'>
                  {assessment?.assessment_name}
                </h3>
              </div>
              {scatterData !== undefined ? (
                <div className='mx-auto flex w-full flex-col'>
                  {scatterData?.length === 0 ? (
                    <div className='mx-auto flex w-full flex-row items-center justify-center rounded-2xl p-10'>
                      <p className='text-4xl font-normal text-gray-200'>
                        No Records
                      </p>
                    </div>
                  ) : (
                    <div className='mx-auto flex w-full flex-col'>
                      <div className='mx-auto flex w-full flex-col'>
                        <ActionDetailsScatter
                          data={scatterData}
                          assessment={assessment}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className='mx-auto flex w-full flex-row items-center justify-center rounded-2xl p-10'>
                  <Oval
                    height={80}
                    width={80}
                    color='#0CB2DA'
                    wrapperStyle={{}}
                    wrapperClass=''
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor='#e5e7eb'
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={`${
              !assessmentIsNullOrNone ? 'xl:w-1/3 ' : 'xl:w-full'
            } mt-12 flex flex-col space-y-8 xl:mt-0`}
          >
            <DatePicker
              label={'Next Check*'}
              name={'Next Check'}
              data={nextCheck}
              setData={setNextCheck}
              labelColor={nextCheckLabelColor}
              borderColor={nextCheckBorderColor}
            />
            <TextBox
              name={'Comment'}
              displayName={'Comment*'}
              data={comment}
              setData={setComment}
              rows={4}
              labelColor={commentLabelColor}
              borderColor={commentBorderColor}
            />
            {!assessmentIsNullOrNone && (
              <>
                <div>
                  <label
                    htmlFor={'Progress Assessment Type'}
                    className='block text-sm font-medium text-brandText'
                  >
                    Progress Assessment Type
                  </label>
                  <input
                    className='mt-1 block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                    type='text'
                    disabled={true}
                    value={`${selectedCheckAssessment?.assessment_name}`}
                  />
                </div>
                <DatePicker
                  label={'Assessment Date'}
                  name={'Assessment Date'}
                  data={assessmentDate}
                  setData={setAssessmentDate}
                />
                <TextBox
                  name={'Assessment Note'}
                  displayName={'Assessment Note'}
                  data={assessmentComment}
                  setData={setAssessmentComment}
                  rows={2}
                />
                <div className='flex flex-row space-x-8'>
                  <div className='flex w-full flex-col'>
                    <label
                      htmlFor={
                        assessment?.score1_name
                          ? assessment?.score1_name
                          : 'Primary Score'
                      }
                      className='block text-sm font-medium text-brandText'
                    >
                      {assessment?.score1_name
                        ? assessment?.score1_name
                        : 'Primary Score'}
                    </label>
                    <div className='mt-1 flex w-full flex-col'>
                      <input
                        type={'number'}
                        name={'Primary Score'}
                        id={'primary-score'}
                        onChange={(e) => {
                          setAssessmentPrimaryScore(e.target.value);
                        }}
                        value={assessmentPrimaryScore}
                        className='block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                      />
                    </div>
                  </div>
                  <div className='flex w-full flex-col'>
                    <label
                      htmlFor={
                        assessment?.score2_name
                          ? assessment?.score2_name
                          : 'Alt Score'
                      }
                      className='block text-sm font-medium text-brandText'
                    >
                      {assessment?.score2_name
                        ? assessment?.score2_name
                        : 'Alt Score'}
                    </label>
                    <div className='mt-1 flex w-full flex-col'>
                      <input
                        type={'number'}
                        name={'Alt Score'}
                        id={'alt-score'}
                        onChange={(e) => {
                          setAssessmentAltScore(e.target.value);
                        }}
                        value={assessmentAltScore}
                        className='block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              onClick={validateCheck}
              className={`${
                !assessmentIsNullOrNone && 'xl:w-full '
              } flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 md:w-40`}
            >
              Check In
            </button>
          </div>
        </div>
      </div>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={messageOne}
        messageTwo={messageTwo}
      />
      <WarningModal
        open={showCheckWarning}
        setOpen={setShowCheckWarning}
        title={'Warning! - Updating Assessment Type'}
        message={
          'If you select an assessment type that is different than the one assigned to this intervention, the score will not be shown on the check in scatter chart. Are you sure you want to change the assessment type?'
        }
        action={handleCheck}
        buttonText={'Confirm'}
      />
      <DisclaimerModal
        title={'Progress Assessment'}
        open={showDisclaimer}
        setOpen={setShowDisclaimer}
        message={
          'The Progress Assessment fields and graph are currently hidden as there is no assessment type associated with this action. To view and enter this information, please update the intervention with the relevant assessment type.'
        }
      />
    </div>
  );
}
