import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';
import FormSpinner from '../../../components/formComponents/FormSpinner';
import NoRecords from '../../../components/formComponents/NoRecords';

export default function AssessmentsTable({ loading, data }) {
  return (
    <div className='mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
      <div className='sm:flex sm:items-center'></div>
      <div className='flex flex-row items-center justify-end'>
        <div className='flex w-auto items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary'>
          <Link to='/mtss/add-assessment'>Add</Link>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Assessment Name
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:hidden'
              >
                Assessment Details
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Primary Score Title
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Minimum Score for Success
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Minimum Score for Warning
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Failure Score at or Below
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Alternate Score Title
              </th>
              <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                <span className='sr-only'>Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {data?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <span className='font-bold text-brandText xl:hidden'>
                    Assessment Name:{' '}
                  </span>
                  {item?.assessment_name}
                  <dl className='font-normal xl:hidden'>
                    <dt className='sr-only'>Primary Score Title</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>Primary Score Title: </span>
                      {item?.score1_name}
                    </dd>
                    <dt className='sr-only'>Minimum Score for Success</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>
                        Minimum Score for Success:{' '}
                      </span>
                      {item?.score1_success}
                    </dd>
                    <dt className='sr-only'>Minimum Score for Warning</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>
                        Minimum Score for Warning:{' '}
                      </span>
                      {item?.score1_warning}
                    </dd>
                    <dt className='sr-only'>Failure Score at or Below</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>
                        Failure Score at or Below:{' '}
                      </span>
                      {item?.score1_danger}
                    </dd>
                    <dt className='sr-only'>Alternate Score Title</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>Alternate Score Title: </span>
                      {item?.score2_name}
                    </dd>
                  </dl>
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.score1_name}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.score1_success}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.score1_warning}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.score1_danger}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.score2_name}
                </td>
                <td className='py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                  <Link
                    to={`/mtss/assessment-update/${item.id}`}
                    className='font-medium text-brandPrimary hover:text-brandPrimaryLight'
                  >
                    Edit<span className='sr-only'>Edit</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={data} />
      </div>
    </div>
  );
}
