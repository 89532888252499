export default function AAGStateIdAndContacts({ student }) {
  return (
    <div className='flex h-full w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-5 text-center text-brandText shadow-md md:w-64'>
      <div>
        <div className='bg-gradient-to-r from-slate-100 to-white'>
          <h2 className='font-medium'>State ID</h2>
        </div>
        <p className='px-5 pt-2 text-xs text-brandText'>{student?.state_id}</p>
      </div>
      <div className='mt-4'>
        <div className='bg-gradient-to-r from-slate-100 to-white'>
          <h2 className='font-medium'>Contacts</h2>
        </div>
        <p className='px-5 pt-2 text-xs text-brandText'>
          <span className='font-semibold'>{student?.guard1_name}</span>{' '}
          {student?.guard1_cell}
        </p>
        <p className='px-5 pt-2 text-xs text-brandText'>
          <span className='font-semibold'>{student?.guard2_name}</span>{' '}
          {student?.guard2_cell}
        </p>
      </div>
    </div>
  );
}
