import absencesIcon from '../../images/circle_icons/absences.svg';
import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function AttendanceTable({ loading, data, timeframe }) {
  return (
    <div className='flex w-full flex-row space-x-8 text-brandText'>
      <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img
                className='h-12 w-12 sm:h-14 sm:w-14'
                src={absencesIcon}
                alt='Grades'
              />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>Absences</h2>
            <p className='ml-2 text-sm'>{timeframe}</p>
          </div>
        </div>
        <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          <thead>
            <tr className='text-base'>
              <th className='p-4 pl-9 pt-1 pb-1 text-left text-base font-medium sm:pl-14 md:text-2xl'>
                Date
              </th>
              <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
                Absent&nbsp;Minutes
              </th>
              <th className='p-4 pr-9 pt-1 pb-1 text-left text-base font-medium sm:pr-14 md:text-2xl'>
                Scheduled&nbsp;Minutes
              </th>
            </tr>
          </thead>
          <tbody className='bg-white text-brandText'>
            {data?.map((item) => (
              <tr key={item?.id}>
                <td className='p-4 py-2 pl-9 font-medium sm:pl-14'>
                  {item?.date}
                </td>
                <td className='p-4 py-2 font-medium'>{item?.absent_minutes}</td>
                <td className='p-4 py-2 pr-9 font-medium sm:pr-14'>
                  {item?.scheduled_minutes}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={data} />
      </div>
    </div>
  );
}
