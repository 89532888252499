import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import SchoolListTable from '../components/sections/Dashboard/SchoolListTable';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';
import Pagination from '../components/Pagination';

export default function TeacherList() {
  const { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showPagination, setShowPagination] = useState(true);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSchools = schools.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  useEffect(() => {
    getSchools();
    postPageView(baseUrl, user.name, user.role, 'school-list');
  }, []);

  let getSchools = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/sis/schools`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <SchoolListTable
                loading={loading}
                data={currentSchools}
                allData={schools}
                setShowPagination={setShowPagination}
              />
              {showPagination && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={schools?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  currentItems={currentSchools}
                />
              )}
            </div>
          }
          heading={'Schools'}
        />
      </div>
    </AccessWrapper>
  );
}
