export default function CheckBox({
  label,
  srName,
  id,
  name,
  text,
  textHint,
  isChecked,
  setIsChecked,
}) {
  let handleCheck = () => {
    isChecked ? setIsChecked(false) : setIsChecked(true);
  };

  return (
    <fieldset className=''>
      <legend className='sr-only'>Notifications</legend>
      <label
        htmlFor={id}
        className='block text-base font-medium text-brandText'
      >
        {label}
      </label>
      <div className='relative mt-1 flex items-start'>
        <div className='flex h-5 items-center'>
          <input
            id={id}
            name={name}
            type='checkbox'
            onChange={handleCheck}
            checked={isChecked}
            value={isChecked}
            className='h-4 w-4 rounded border-gray-300 text-brandPrimary focus:ring-brandPrimary'
          />
        </div>
        <div className='ml-3 text-sm'>
          <label htmlFor={id} className='font-medium text-brandText'>
            {text}
          </label>
          <span className='text-brandText'>{` ${textHint}`}</span>
        </div>
      </div>
    </fieldset>
  );
}
