import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../formComponents/TextInput';
import MultiCheckBox from '../../formComponents/MultiCheckBox';
import Notification from '../../formComponents/Notification';

export default function SubjectsForm() {
  const { authTokens, logoutUser, baseUrl, user } = useContext(AuthContext);
  const [subjectBorderColor, setSubjectBorderColor] =
    useState('border-gray-300');
  const [subjectLabelColor, setSubjectLabelColor] = useState('text-brandText');
  const [schoolLabelColor, setSchoolLabelColor] = useState('text-brandText');
  const [schoolBorderColor, setSchoolBorderColor] = useState('border-gray-300');

  /* state variables to hold json data from the back-end */
  const [schools, setSchools] = useState([]);

  /* state variables to hold form data to submit to the back-end */
  const [subjectName, setSubjectName] = useState('');
  const [selectedSchools, setSelectedSchools] = useState([]);

  const [show, setShow] = useState(false);

  const validateFormData = (e) => {
    e.preventDefault();
    if (subjectName?.trim().length >= 1 && selectedSchools.length !== 0) {
      setSubjectBorderColor('border-gray-300');
      setSubjectLabelColor('text-brandText');
      setSchoolLabelColor('text-brandText');
      setSchoolBorderColor('border-gray-300');
      handleSubmit(e);
    } else {
      if (subjectName?.trim().length >= 1) {
        setSubjectBorderColor('border-gray-300');
        setSubjectLabelColor('text-brandText');
      } else {
        setSubjectBorderColor('border-red-500');
        setSubjectLabelColor('text-red-500');
      }

      if (selectedSchools.length !== 0) {
        setSchoolLabelColor('text-brandText');
        setSchoolBorderColor('border-gray-300');
      } else {
        setSchoolLabelColor('text-red-500');
        setSchoolBorderColor('border-red-500');
      }

      setShow(true);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(`${baseUrl}/mtss/subjects/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          subject_name: subjectName,
          school: selectedSchools,
          status: true,
        }),
      });

      let data = await response.json();

      if (response.status === 201) {
        navigate(-1);
      } else {
        setShow(true);
        console.log(data);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/add-subject',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to add a new subject and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/add-subject',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to add a new subject and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  let getSchools = async () => {
    let response = await fetch(`${baseUrl}/sis/schools/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  return (
    <div>
      <form
        className='mx-auto flex max-w-3xl flex-col space-y-8 px-4 py-12 sm:py-16 sm:px-6 lg:px-8 lg:py-20'
        action=''
      >
        <TextInput
          name={'subject_name'}
          displayName={'Subject Name*'}
          data={subjectName}
          setData={setSubjectName}
          labelColor={subjectLabelColor}
          borderColor={subjectBorderColor}
        />
        <MultiCheckBox
          title={'School*'}
          hint={''}
          data={schools}
          setSelectedData={setSelectedSchools}
          dotName={'name'}
          labelColor={schoolLabelColor}
          borderColor={schoolBorderColor}
        />
        <button
          onClick={validateFormData}
          className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Submit
        </button>
      </form>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been completed.'
        }
      />
    </div>
  );
}
