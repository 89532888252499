import AtAGlanceModal from './AtAGlanceModal';
import React, { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Link } from 'react-router-dom';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function NotesAndReferralsTable({ loading, data }) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [student, setStudent] = useState(null);
  const [filterByStudentState, setFilterByStudentState] = useState(false);
  const [displayData, setDisplayData] = useState();

  const handleClick = (item) => {
    setOpen(true);
    setId(item.id);
  };

  let people = data;

  const [query, setQuery] = useState('');
  const [selectedPerson, setSelectedPerson] = useState();

  let filteredPeople =
    query === ''
      ? people
      : people?.filter((person) => {
          return person?.student?.last_name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const filterByStudent = () => {
    setFilterByStudentState(true);
    setDisplayData(
      filteredPeople
        ?.slice()
        .sort((a, b) => (a.student?.last_name > b.student?.last_name ? 1 : -1)),
    );
  };

  const filterByDate = () => {
    setFilterByStudentState(false);
    setDisplayData(filteredPeople);
  };

  useEffect(() => {
    setDisplayData(filteredPeople);
  }, [query, people]);

  return (
    <div className='px-4 pb-12 sm:px-6 sm:pb-16 lg:px-8 lg:pb-20'>
      <div className='sm:flex sm:items-center'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:max-w-sm'>
          <Combobox
            as='div'
            value={selectedPerson}
            onChange={setSelectedPerson}
          >
            <div className='relative mt-2'>
              <Combobox.Input
                className='w-full rounded-md border-2 border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
                onChange={(event) => setQuery(event.target.value)}
                onBlur={() => {
                  filteredPeople = people;
                  setQuery('');
                }}
                displayValue={(person) => person?.first_name}
                autoComplete='off'
                placeholder='Filter by Last Name'
              />
            </div>
          </Combobox>
        </div>
        <div className='mt-5 flex w-full flex-row lg:mt-0 lg:w-auto'>
          <button
            className='mr-3 flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary lg:w-auto'
            onClick={() => {
              filterByStudent();
            }}
          >
            Sort by student
          </button>
          <button
            className='flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary lg:w-auto'
            onClick={() => {
              filterByDate();
            }}
          >
            Sort by date
          </button>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Date
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Student
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white sm:table-cell'
              >
                Issue
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-lg font-bold text-white'
              >
                Action
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-lg font-bold text-white'
              >
                Type
              </th>
              <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                <span className='sr-only'>Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  {item?.created_at}
                  <dl className='font-normal lg:hidden'>
                    <dt className='sr-only'>Student</dt>
                    <dd className='mt-1 truncate text-brandPrimary'>
                      <button
                        className='rounded-md hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                        onClick={() => {
                          handleClick(item?.student);
                        }}
                      >
                        {item?.student?.first_name +
                          ' ' +
                          item?.student?.last_name}
                      </button>
                    </dd>
                    <dt className='sr-only sm:hidden'>Issue</dt>
                    <dd className='mt-1 truncate text-brandText sm:hidden'>
                      {item?.issue}
                    </dd>
                  </dl>
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandPrimary hover:text-brandPrimaryLight lg:table-cell'>
                  <button
                    className='rounded-md duration-300 ease-in-out hover:scale-110 focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                    onClick={() => {
                      handleClick(item?.student);
                    }}
                  >
                    {item?.student?.first_name + ' ' + item?.student?.last_name}
                  </button>
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText sm:table-cell'>
                  {item?.issue}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-gray-500'>
                  {item?.teacher_actions}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-gray-500'>
                  {item?.is_referral ? 'Referral' : 'Note'}
                </td>
                <td className='py-4 pl-3 pr-4 text-right text-sm font-medium duration-300 ease-in-out hover:scale-110 sm:pr-6'>
                  <Link
                    to={`/mtss/note-referral-update/${item?.id}`}
                    className='font-medium text-brandPrimary hover:text-brandPrimaryLight'
                  >
                    Edit<span className='sr-only'>Edit</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
      <AtAGlanceModal open={open} setOpen={setOpen} id={id} />
    </div>
  );
}
