import axios from 'axios';

export const getAttendanceSummary = async (
  authTokens,
  logout,
  studentId,
  baseUrl,
) => {
  const url = `${baseUrl}/sis/attendance-summary/${studentId}/`;
  try {
    const data = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + String(authTokens?.access),
      },
    });
    return data;
  } catch (error) {
    if (error?.response?.data?.code === 'token_not_valid') {
      logout();
    }
    throw error;
  }
};

export const getHistoricalGradeSummary = async (
  authTokens,
  logout,
  studentId,
  baseUrl,
) => {
  const url = `${baseUrl}/sis/historical-grade-summary/${studentId}/`;
  try {
    const data = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + String(authTokens?.access),
      },
    });
    return data;
  } catch (error) {
    if (error?.response?.data?.code === 'token_not_valid') {
      logout();
    }
    throw error;
  }
};
