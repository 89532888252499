import SelectMenu from '../../formComponents/SelectMenu';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Notification from '../../formComponents/Notification';
import UpdateSelectMenu from '../../formComponents/UpdateSelectMenu';

export default function ReferralPathForward({ referralDetails }) {
  let { authTokens, logoutUser, baseUrl } = useContext(AuthContext);

  /* state variables to hold json data from the back-end */
  let [resolutions, setResolutions] = useState([]);

  /* state variables to hold form data to submit to the back-end */
  let [selectedResolution, setSelectedResolution] = useState();

  const [show, setShow] = useState(false);
  const [tempArray, setTempArray] = useState([]);

  const navigate = useNavigate();

  const navigateToForm = () => {
    navigate(`/mtss/add-action`, {
      state: {
        studentFirstName: referralDetails?.student?.first_name,
        studentLastName: referralDetails?.student?.last_name,
        studentId: referralDetails?.student?.id,
        referralId: referralDetails?.id,
      },
    });
  };

  useEffect(() => {
    getResolutions();
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    let response = await fetch(
      `${baseUrl}/mtss/referrals/${referralDetails.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          student: referralDetails?.student?.id,
          resolution: selectedResolution?.id,
        }),
      },
    );

    if (response.status === 200) {
      navigate(-1);
    } else {
      setShow(true);
    }
  };

  let getResolutions = async () => {
    let response = await fetch(`${baseUrl}/mtss/resolutions/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setResolutions(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const navigateToAAG = () => {
    navigate(`/students/at-a-glance/${referralDetails?.student?.id}`);
  };

  useEffect(() => {
    if (referralDetails?.resolution?.id !== undefined) {
      setTempArray(
        resolutions?.filter((obj) => {
          return obj.id === referralDetails?.resolution?.id;
        }),
        setSelectedResolution(tempArray[0]),
      );
    } else {
      setSelectedResolution(resolutions[0]);
    }
  }, [referralDetails, resolutions, referralDetails?.resolution?.id]);

  return (
    <div className='mt-5 flex w-full flex-col justify-between px-4 sm:px-6 lg:flex-row lg:items-center lg:px-8'>
      <div className='flex w-full flex-row lg:mt-0 lg:w-auto'>
        <button
          onClick={() => {
            navigateToAAG();
          }}
          className='mr-3 flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:hover:scale-105 lg:w-auto'
        >
          View Student Profile
        </button>
        <button
          className='flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:hover:scale-105 lg:mr-3 lg:w-auto'
          onClick={() => {
            navigateToForm();
          }}
        >
          Add Actions
        </button>
      </div>
      <div className=' mt-5 flex w-full flex-col lg:mt-0 lg:max-w-xl lg:flex-row'>
        <div className='flex w-full'>
          {/* <SelectMenu
            label={''}
            data={resolutions}
            selectedData={selectedResolution}
            setSelectedData={setSelectedResolution}
            dotSelector={'resolution_name'}
          /> */}
          <UpdateSelectMenu
            label={''}
            data={resolutions}
            selectedData={selectedResolution}
            setSelectedData={setSelectedResolution}
            dotSelector={'resolution_name'}
          />
        </div>
        <button
          onClick={handleSubmit}
          className='mt-3 flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:hover:scale-105 lg:mt-0 lg:ml-3 lg:w-auto'
        >
          Update
        </button>
      </div>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been completed.'
        }
      />
    </div>
  );
}
