import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import UpdateMultiCheckBox from '../../formComponents/UpdateMultiCheckBox';
import CheckBox from '../../formComponents/CheckBox';
import Notification from '../../formComponents/Notification';

export default function MtssMemberManagementForm() {
  const { authTokens, logoutUser, baseUrl, user } = useContext(AuthContext);

  /* state variables to hold json data from the back-end */
  let [schools, setSchools] = useState([]);
  const [previousDetails, setPreviousDetails] = useState();

  /* state variables to hold form data to submit to the back-end */
  const [isSst, setIsSst] = useState(false);
  const [sstSchools, setSstSchools] = useState([]);
  const [isCaregiver, setIsCaregiver] = useState(false);
  const [cgSchools, setCgSchools] = useState({});

  const [show, setShow] = useState(false);

  useEffect(() => {
    getSchools();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        `${baseUrl}/core/profiles/${location?.state?.item?.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
          body: JSON.stringify({
            is_sst: isSst,
            sst_schools: sstSchools,
            is_caregiver: isCaregiver,
            cg_schools: cgSchools,
          }),
        },
      );

      let data = await response.json();

      if (response.status === 200) {
        navigate(-1);
      } else {
        setShow(true);
        console.log(data);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/update-user',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to update the selected user and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/update-user',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to update the selected user and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  let getSchools = async () => {
    let response = await fetch(`${baseUrl}/sis/schools/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  useEffect(() => {
    setIsSst(location?.state?.item?.is_sst);
    setSstSchools(location?.state?.item?.sst_schools);
    setIsCaregiver(location?.state?.item?.is_caregiver);
    setCgSchools(location?.state?.item?.cg_schools);
  }, [previousDetails]);

  return (
    <div>
      <form
        className='mx-auto flex max-w-3xl flex-col space-y-8 px-4 py-12 sm:px-6 sm:py-16 lg:px-8 lg:py-20'
        action=''
      >
        <CheckBox
          label={'SST Status'}
          srName={'SST Member?'}
          id={'is_sst'}
          name={'is_sst'}
          text={'SST?'}
          textHint={''}
          isChecked={isSst}
          setIsChecked={setIsSst}
        />
        <UpdateMultiCheckBox
          title={'SST Schools'}
          hint={''}
          previousData={location?.state?.item?.sst_schools}
          data={schools}
          setSelectedData={setSstSchools}
          dotName={'name'}
        />
        <CheckBox
          label={'Caregiver Status'}
          srName={'Is Caregiver?'}
          id={'is_caregiver'}
          name={'is_caregiver'}
          text={'Caregiver?'}
          textHint={''}
          isChecked={isCaregiver}
          setIsChecked={setIsCaregiver}
        />
        <UpdateMultiCheckBox
          title={'Caregiver Schools'}
          hint={''}
          previousData={location?.state?.item?.cg_schools}
          data={schools}
          setSelectedData={setCgSchools}
          dotName={'name'}
        />
        <button
          onClick={handleSubmit}
          className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Submit
        </button>
      </form>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been completed.'
        }
      />
    </div>
  );
}
