import MultiRangeSlider from './MultiRangeSlider';

export default function StudentSliderSort({
  elaMin,
  setElaMin,
  elaMax,
  setElaMax,
  mathMin,
  setMathMin,
  mathMax,
  setMathMax,
  attendanceMin,
  setAttendanceMin,
  attendanceMax,
  setAttendanceMax,
  referralsMin,
  setReferralsMin,
  referralsMax,
  setReferralsMax,
  totalMin,
  setTotalMin,
  totalMax,
  setTotalMax,
}) {
  return (
    <div className='flex flex-col items-center'>
      <div>
        <MultiRangeSlider
          label={'ELA'}
          min={0}
          max={99}
          onChange={({ min, max }) => {
            setElaMin(min);
            setElaMax(max);
          }}
        />
      </div>
      <div>
        <MultiRangeSlider
          label={'Math'}
          min={0}
          max={99}
          onChange={({ min, max }) => {
            setMathMin(min);
            setMathMax(max);
          }}
        />
      </div>
      <div>
        <MultiRangeSlider
          label={'Attendance'}
          min={0}
          max={50}
          onChange={({ min, max }) => {
            setAttendanceMin(min);
            setAttendanceMax(max);
          }}
        />
      </div>
      <div>
        <MultiRangeSlider
          label={'Referrals'}
          min={0}
          max={20}
          onChange={({ min, max }) => {
            setReferralsMin(min);
            setReferralsMax(max);
          }}
        />
      </div>
      <div>
        <MultiRangeSlider
          label={'Total'}
          min={0}
          max={8}
          onChange={({ min, max }) => {
            setTotalMin(min);
            setTotalMax(max);
          }}
        />
      </div>
    </div>
  );
}
