import AtAGlanceModal from './AtAGlanceModal';
import React, { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function OpenReferralsTable({ loading, data }) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [student, setStudent] = useState(null);
  const [filterByOpenState, setFilterByOpenState] = useState(true);
  const [displayData, setDisplayData] = useState();

  const navigate = useNavigate();

  const navigateToForm = (item) => {
    navigate('/mtss/add-check', {
      state: { id: item.id, studentId: item.student.id },
    });
  };

  const handleClick = (item) => {
    setOpen(true);
    setId(item.id);
  };

  let people = data;

  const [query, setQuery] = useState('');
  /* const [selectedPerson, setSelectedPerson] = useState(); */

  let filteredPeople =
    query === ''
      ? people
      : people?.filter((person) => {
          return person?.student?.last_name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const filterByOpen = () => {
    setFilterByOpenState(true);
    setDisplayData(
      filteredPeople?.slice().filter((item) => {
        return (
          item?.resolution === null
        ); /* item?.resolution?.resolution_name === 'Open' */
      }),
    );
  };

  const filterByAll = () => {
    setFilterByOpenState(false);
    setDisplayData(filteredPeople);
  };

  useEffect(() => {
    setDisplayData(filteredPeople);
    filterByOpenState ? filterByOpen() : filterByAll();
  }, [query, people]);

  useEffect(() => {
    filterByOpen();
  }, [data]);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:items-center md:flex'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:max-w-sm'>
          <Combobox
            as='div'
            /* value={selectedPerson}
            onChange={setSelectedPerson} */
          >
            <div className='relative mt-2'>
              <Combobox.Input
                className='w-full rounded-md border border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(person) => person?.first_name}
                autoComplete='off'
                onBlur={() => {
                  filteredPeople = people;
                  setQuery('');
                }}
                placeholder='Filter by Last Name'
              />
            </div>
          </Combobox>
        </div>
        <div className='mt-5 flex w-full flex-row lg:mt-0 lg:w-auto'>
          <button
            className='mr-3 flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary lg:w-auto'
            onClick={() => {
              filterByOpen();
            }}
          >
            Show Open
          </button>
          <button
            className='flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary lg:w-auto'
            onClick={() => {
              filterByAll();
            }}
          >
            Show All
          </button>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Student
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Author
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Date
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Academic
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Behavioral
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 pr-4 text-left text-lg font-bold text-white sm:pr-6'
              >
                Resolution
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item) => (
              <tr key={item?.id}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <span className='font-bold text-brandText lg:hidden'>
                    Student:{' '}
                  </span>
                  <button
                    className='rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary lg:hover:scale-110'
                    onClick={() => {
                      handleClick(item?.student);
                    }}
                  >
                    {item?.student?.first_name} {item?.student?.last_name}
                  </button>
                  <dl className='font-normal lg:hidden'>
                    <dt className='sr-only'>Author</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Author: </span>
                      {item?.author?.first_name} {item?.author?.last_name}
                    </dd>
                    <dt className='sr-only lg:hidden'>Date</dt>
                    <dd className='mt-1 truncate text-brandText lg:hidden'>
                      <span className='font-bold text-brandText'>Date: </span>
                      {item?.created_at}
                    </dd>
                    <dt className='sr-only'>Academic</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Academic: </span>
                      {item?.academic_comment}
                    </dd>
                    <dt className='sr-only'>Behavioral</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Behavioral: </span>
                      {item?.behavioral_comment}
                    </dd>
                  </dl>
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.author?.first_name} {item?.author?.last_name}
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.created_at}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.academic_comment}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.behavioral_comment}
                </td>

                <td className='px-3 py-4 text-sm font-normal text-brandText'>
                  <dd>
                    <Link
                      to={`/mtss/referrals/${item?.id}`}
                      className='font-medium text-brandPrimary hover:text-brandPrimaryLight'
                    >
                      {item?.resolution?.resolution_name
                        ? item?.resolution.resolution_name
                        : 'Open'}
                    </Link>
                  </dd>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
      <AtAGlanceModal open={open} setOpen={setOpen} id={id} />
    </div>
  );
}
