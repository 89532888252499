import StudentPageTopper from '../../StudentPageTopper';
import React, { useState, useEffect, useRef, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AAGCoursesTable from '../../student_components/AAGCoursesTable';
import AttendanceBarPlaceholder from '../../student_components/AttendanceBarPlaceholder';
import AAGActionsNotesReferrals from '../../student_components/AAGActionsNotesReferrals';
import AtAGlaceInfoCard from '../../student_components/AtAGlanceInfoCard';
import AAGLexileReadingMath from '../../student_components/AAGLexileReadingMath';
import AAGStateIdAndContacts from '../../student_components/AAGStateIdAndContacts';
import AAGCreateANote from '../../student_components/AAGCreateANote';
import tailwindColors from 'tailwindcss/colors';
import { PercentBar } from '../../../components/PercentBar';
import { scaleOrdinal } from 'd3-scale';
import lexileIcon from '../../../images/circle_icons/lexile.svg';
import { Oval } from 'react-loader-spinner';
import { HorizontalBarChart } from '../../../components/HorizontalBarChart';
import { ContentContainer } from '../../../components/ContentContainer';

export default function AtAGlanceWrapper({
  student,
  studentSchedule,
  studentCounts,
  readingScores,
  mathScores,
  studentSummary,
  studentGradeSummary,
  getStudentCounts,
}) {
  const navigate = useNavigate();

  const percentScaleRef = useRef(
    scaleOrdinal().range([tailwindColors.gray[50]]),
  );

  const navigateToForm = () => {
    navigate('/mtss/note-referral', {
      state: {
        studentFirstName: student?.first_name,
        studentLastName: student?.last_name,
        studentId: student?.id,
      },
    });
  };
  return (
    <>
      <div className='-mb-16 bg-white'>
        <StudentPageTopper student={student} />
        <div className='relative -top-16 mx-auto'>
          <section className='mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='mx-auto max-w-7xl '>
              <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                At A Glance
              </h1>
              <div className='mx-auto flex w-full flex-col justify-between md:flex-row md:space-x-6'>
                {studentSchedule === undefined ? (
                  <div className='flex h-80 w-full flex-row items-center justify-center'>
                    <Oval
                      height={80}
                      width={80}
                      color='#0CB2DA'
                      wrapperStyle={{}}
                      wrapperClass=''
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor='#e5e7eb'
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : (
                  <div className='flex w-full'>
                    <AAGCoursesTable
                      studentSchedule={studentSchedule}
                      student={student}
                    />
                  </div>
                )}

                {!studentGradeSummary && (
                  <div className='flex h-80 w-full flex-row items-center justify-center'>
                    <Oval
                      height={80}
                      width={80}
                      color='#0CB2DA'
                      wrapperStyle={{}}
                      wrapperClass=''
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor='#e5e7eb'
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                )}

                {studentGradeSummary && (
                  <div className='mt-5 flex h-full w-full md:mt-0'>
                    {studentGradeSummary?.length !== 0 ? (
                      <div className='flex h-auto w-full flex-row items-center justify-center'>
                        <ContentContainer
                          classArray={['pt-2', 'px-2', 'pr-0', 'h-80']}
                        >
                          <div className='flex'>
                            <div className='mr-3'>
                              <img
                                className='h-12 w-12 sm:h-14 sm:w-14'
                                src={lexileIcon}
                                alt='Lexile'
                              />
                            </div>
                            <div className='flex flex-col justify-center'>
                              <div>
                                <h1 className='text-2xl font-medium text-brandText sm:text-3xl'>
                                  Historical
                                </h1>
                              </div>
                              <div>
                                <p className='text-sm text-brandText'>Grades</p>
                              </div>
                            </div>
                          </div>

                          <div className='pl-3'>
                            <HorizontalBarChart
                              size={[null, 200]}
                              data={studentGradeSummary}
                              axisKeys={['grade_count', 'grade']}
                            />
                          </div>
                        </ContentContainer>
                      </div>
                    ) : (
                      <ContentContainer
                        classArray={[
                          'pt-2',
                          'px-2',
                          'pr-0',
                          'h-80',
                          'mt-5',
                          'md:mt-0',
                        ]}
                      >
                        <div className='flex'>
                          <div className='mr-3'>
                            <img
                              className='h-14 w-14 sm:h-16 sm:w-16'
                              src={lexileIcon}
                              alt='Lexile'
                            />
                          </div>
                          <div className='flex flex-col justify-center'>
                            <div>
                              <h1 className='text-2xl font-medium text-brandText sm:text-3xl'>
                                Historical
                              </h1>
                            </div>
                            <div>
                              <p className='text-sm text-brandText'>Grades</p>
                            </div>
                          </div>
                        </div>

                        <div className='flex h-full flex-col justify-center text-center text-xl font-medium text-brandText'>
                          <p className='px-8 pb-8'>
                            Insufficient student data to display grade history.
                          </p>
                        </div>
                      </ContentContainer>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className='mx-auto mt-8 bg-gray-100 px-4 pb-7 pt-4 sm:px-6 lg:px-8'>
            <div className='mx-auto max-w-7xl'>
              <h2 className='mb-2 ml-2 font-semibold text-brandText'>
                Attendance
              </h2>
            </div>
            <div className='mx-auto flex w-full max-w-7xl flex-col md:flex-row md:space-x-5 xl:space-x-8'>
              <div className='flex w-full flex-col space-y-5 md:space-y-8'>
                <div className=''>
                  <div className='mb-1'>
                    {studentSummary && (
                      <PercentBar
                        totalNumber={100}
                        currentNumber={studentSummary.attendance_percentage}
                        barScale={percentScaleRef.current}
                      />
                    )}
                  </div>
                  <div className='flex'>
                    {studentSummary && (
                      <p className='ml-auto mr-2 text-sm font-semibold text-black'>
                        {studentSummary.attendance_percentage}%
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <AAGActionsNotesReferrals studentCounts={studentCounts} />
                </div>
              </div>
              <div className='mt-5 flex flex-row space-x-5 md:mt-0 xl:space-x-8'>
                <div className='flex w-full'>
                  <AtAGlaceInfoCard student={student} />
                </div>
                <div className='flex w-full flex-col'>
                  <AAGLexileReadingMath
                    readingScores={readingScores}
                    mathScores={mathScores}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* this is the next section */}
          <section className='mx-auto'>
            <div className='mx-auto mt-3 flex w-full flex-row space-x-8 px-4 pb-7 pt-7 sm:px-6 lg:mt-8 lg:px-8'>
              <div className='mx-auto flex w-full max-w-7xl flex-col md:flex-row md:space-x-5 xl:space-x-8'>
                <div className='flex w-full md:w-auto'>
                  <AAGStateIdAndContacts student={student} />
                </div>
                <div className='mt-5 flex w-full md:mt-0'>
                  <AAGCreateANote student={student} update={getStudentCounts} />
                </div>
              </div>
            </div>
            <div className='mb-12 px-4 sm:mb-16 sm:px-6 lg:mb-20 lg:px-8'>
              <div className='mx-auto mt-0 flex max-w-7xl flex-row justify-end'>
                <button
                  onClick={navigateToForm}
                  className='rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                >
                  Create Referral
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
