import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function CustomBarChart({
  title,
  subtitle,
  icon,
  data,
  loading,
  selectorOne,
  selectorTwo,
  customNoDataMessage = 'No Records',
  customMessageClasses = 'text-4xl text-gray-200',
}) {
  const [chartRendered, setChartRendered] = useState(false);

  useEffect(() => {
    if (!loading) {
      // Delay the re-render by 1.25 seconds
      const timeout = setTimeout(() => {
        setChartRendered(true);
      }, 1250);

      return () => clearTimeout(timeout);
    }
  }, [loading]);

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;

    return (
      <text
        x={x + width + 25}
        y={y + height - height / 2 + 5}
        fill='#636363'
        textAnchor='end'
      >
        {value}
      </text>
    );
  };

  return (
    <div className='flex h-96 w-full flex-row space-x-8 text-brandText'>
      <div className='flex h-full w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-3 shadow-md'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img
                className='h-12 w-12 sm:h-14 sm:w-14'
                src={icon}
                alt='icon'
              />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>{title}</h2>
            <p className='ml-2 text-sm'>{subtitle}</p>
          </div>
        </div>
        {!loading && data?.length > 0 ? (
          <div className='mt-3 flex h-full w-full px-6 font-bold text-brandText'>
            {chartRendered && (
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  layout='vertical'
                  barCategoryGap='23%'
                  margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='1' y2='0'>
                      <stop offset='0' stopColor='#11CCEA' stopOpacity={1} />
                      <stop offset='1' stopColor='#e9fbfd' stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <XAxis type='number' hide />
                  <YAxis
                    type='category'
                    width={30}
                    padding={{ left: 20 }}
                    dataKey={selectorOne}
                    tickLine={false}
                    axisLine={false}
                    tick={{ fontSize: 18 }}
                  />

                  <Bar
                    dataKey={selectorTwo}
                    radius={[5, 5, 5, 5]}
                    fill='url(#colorUv)'
                  >
                    <LabelList
                      dataKey={selectorTwo}
                      content={renderCustomizedLabel}
                      position='insideRight'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        ) : (
          <div
            className={`${customMessageClasses} flex h-full w-full flex-col items-center justify-center pb-5 font-normal`}
          >
            {loading || !chartRendered ? (
              <div>
                <Oval
                  height={80}
                  width={80}
                  color='#0CB2DA'
                  wrapperStyle={{}}
                  wrapperClass=''
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor='#e5e7eb'
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div>{customNoDataMessage}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
