import SidebarLayout from '../components/SidebarLayout';
import Cards from '../components/Cards';
import Card from '../components/Card';
import AccessWrapper from '../components/AccessWrapper';

/* Tiles */
/* import categoriesCard from '../images/dashboard_cards/categories.webp'; */
import subjectsCard from '../images/dashboard_cards/subjects.webp';
import concernsCard from '../images/dashboard_cards/concerns.webp';
import treatmentsCard from '../images/dashboard_cards/treatments.webp';
import outcomesCard from '../images/dashboard_cards/outcomes.webp';
import staffManagementCard from '../images/dashboard_cards/staff_management.webp';
import assessmentsCard from '../images/dashboard_cards/assessments.png';

/* Icons */
/* import categoriesIcon from '../images/dashboard_icons/categories.svg'; */
import subjectsIcon from '../images/dashboard_icons/subjects.svg';
import concernsIcon from '../images/dashboard_icons/concerns.svg';
import treatmentsIcon from '../images/dashboard_icons/treatments.svg';
import outcomesIcon from '../images/dashboard_icons/outcomes.svg';
import staffManagementIcon from '../images/dashboard_icons/staff_management.svg';
import assessmentsIcon from '../images/dashboard_icons/assessments.svg';

export default function MtssManagement() {
  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-20'>
              <div>
                <Cards
                  cardProps={
                    <>
                      <Card
                        cardImage={subjectsCard}
                        href={'/mtss-management/subjects'}
                        cardName={'Subjects'}
                        cardIcon={subjectsIcon}
                      />
                      <Card
                        cardImage={concernsCard}
                        href={'/mtss-management/concerns'}
                        cardName={'Concerns'}
                        cardIcon={concernsIcon}
                      />
                      <Card
                        cardImage={treatmentsCard}
                        href={'/mtss-management/treatments'}
                        cardName={'Treatments'}
                        cardIcon={treatmentsIcon}
                      />
                      <Card
                        cardImage={outcomesCard}
                        href={'/mtss-management/outcomes'}
                        cardName={'Outcomes'}
                        cardIcon={outcomesIcon}
                      />
                      <Card
                        cardImage={assessmentsCard}
                        href={'/mtss-management/assessments'}
                        cardName={'Assessments'}
                        cardIcon={assessmentsIcon}
                      />
                      <Card
                        cardImage={staffManagementCard}
                        href={'/mtss-management/staff-management'}
                        cardName={'Staff Management'}
                        cardIcon={staffManagementIcon}
                      />
                    </>
                  }
                />
              </div>
            </div>
          }
          heading={'MTSS Management'}
          mtssManagementCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
