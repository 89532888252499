import { useEffect, useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function StudentComboBox({
  people,
  selectedPerson,
  setSelectedPerson,
  label,
  initialValue,
  labelColor,
  borderColor,
}) {
  const [query, setQuery] = useState('');

  let placeholder = initialValue;

  let setPlaceholder = () => {
    placeholder = initialValue;
  };

  useEffect(() => {
    setPlaceholder();
  });

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as='div' value={selectedPerson} onChange={setSelectedPerson}>
      <Combobox.Label
        className={`${labelColor} block text-sm font-medium text-brandText`}
      >
        {label}
      </Combobox.Label>
      <div className='relative mt-1'>
        <Combobox.Input
          className={`${borderColor} w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-brandText shadow-sm focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm`}
          onChange={(e) => setQuery(e.target.value)}
          displayValue={(person) => (person ? person?.name : '')}
          placeholder={'Make a selection'}
        />
        <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person?.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-brandPrimary text-white' : 'text-brandText',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {person.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-brandPrimary',
                        )}
                      >
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
