import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';
import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function Table({
  loading,
  data,
  path,
  dotSelector,
  createNewLink,
}) {
  return (
    <div className='mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
      <div className='sm:flex sm:items-center'></div>
      <div className='flex flex-row items-center justify-end'>
        <div className='flex w-auto items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary'>
          <Link to={createNewLink}>Add</Link>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Name
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Schools
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Status
              </th>
              <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                <span className='sr-only'>Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {data?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:pl-6'>
                  {item[dotSelector]}
                </td>
                <td className='py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:pl-6'>
                  {item.school.map((school, itemIdx) => (
                    <div key={itemIdx} className='flex flex-row items-center'>
                      <div className='mr-2 h-2 w-2 rounded-full bg-brandPrimary' />
                      <dd className='text-brandText'>{school.name}</dd>
                    </div>
                  ))}
                </td>
                <td className='py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:pl-6'>
                  {item?.status === true ? (
                    <CheckCircleIcon className='ml-5 h-5 w-5 text-brandPrimary' />
                  ) : (
                    <XCircleIcon className='ml-5 h-5 w-5 text-gray-300' />
                  )}
                </td>
                <td className='py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                  <Link
                    to={`${path}${item.id}`}
                    className='font-medium text-brandPrimary hover:text-brandPrimaryLight'
                  >
                    Edit<span className='sr-only'>Edit</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={data} />
      </div>
    </div>
  );
}
