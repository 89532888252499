import React from 'react';

export default function Pagination({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  currentItems,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav
      className='mt-5 flex items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6 md:mt-7 lg:px-8'
      aria-label='Pagination'
    >
      <div className='hidden sm:block'>
        <p className='text-sm text-gray-700'>
          Showing{' '}
          <span className='font-medium'>
            {currentPage * itemsPerPage - (itemsPerPage - 1)}
          </span>{' '}
          to{' '}
          <span className='font-medium'>
            {currentPage * itemsPerPage -
              (itemsPerPage - 1) +
              (currentItems?.length - 1)}
          </span>{' '}
          of <span className='font-medium'>{totalItems}</span> results
        </p>
      </div>
      <div className='flex flex-1 justify-between sm:justify-end'>
        <button
          onClick={() => {
            currentPage !== 1 && paginate(currentPage - 1);
          }}
          className='rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Previous
        </button>
        <button
          onClick={() => {
            currentPage !== pageNumbers[pageNumbers?.length - 1] &&
              paginate(currentPage + 1);
          }}
          className='ml-3 rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Next
        </button>
      </div>
    </nav>
  );
}
