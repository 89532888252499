import FilterMultiCheck from './formComponents/FilterMultiCheck';

export default function SchoolDetailsFilter({
  ethnicityOptions,
  sesOptions,
  setSelectedGenderOptions,
  setSelectedEthnicityOptions,
  setSelectedSesOptions,
  setSelected504Options,
  setSelectedIepOptions,
}) {
  const genderOptions = [
    { gender: 'Male', id: 'm' },
    { gender: 'Female', id: 'f' },
  ];
  const fiveZeroFourOptions = [
    { status: 'N', id: 0 },
    { status: 'Y', id: 1 },
  ];
  const iepOptions = [
    { status: 'N', id: 0 },
    { status: 'Y', id: 1 },
  ];

  return (
    <div className='flex max-h-[calc(100vh-236px)] flex-col space-y-6 overflow-y-auto overflow-x-hidden pb-10 lg:max-h-[calc(100vh-288px)]'>
      <FilterMultiCheck
        label={'Gender'}
        data={genderOptions}
        dotName={'gender'}
        idSelector={'gender'}
        setSelectedData={setSelectedGenderOptions}
      />
      <FilterMultiCheck
        label={'Ethnicity'}
        data={ethnicityOptions}
        dotName={'name'}
        idSelector={'ethnicity'}
        setSelectedData={setSelectedEthnicityOptions}
      />
      <FilterMultiCheck
        label={'SES'}
        data={sesOptions}
        dotName={'name'}
        idSelector={'ses'}
        setSelectedData={setSelectedSesOptions}
      />
      <FilterMultiCheck
        label={'504'}
        data={fiveZeroFourOptions}
        dotName={'status'}
        idSelector={'504'}
        setSelectedData={setSelected504Options}
      />
      <FilterMultiCheck
        label={'IEP'}
        data={iepOptions}
        dotName={'status'}
        idSelector={'iep'}
        setSelectedData={setSelectedIepOptions}
      />
    </div>
  );
}
