import SidebarLayout from '../../components/SidebarLayout';
import TreatmentsUpdateForm from '../../components/sections/MTSS/TreatmentsUpdateForm';
import { useParams } from 'react-router-dom';
import AccessWrapper from '../../components/AccessWrapper';
import AuthContext from '../../context/AuthContext';
import { useContext, useEffect } from 'react';

export default function TreatmentsUpdate() {
  const { postPageView, user, baseUrl } = useContext(AuthContext);

  useEffect(() => {
    postPageView(baseUrl, user.name, user.role, 'form/update-treatment');
  }, []);
  const params = useParams();
  const id = params?.id;
  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={<TreatmentsUpdateForm params={id} />}
          heading={'Update Treatment'}
        />
      </div>
    </AccessWrapper>
  );
}
