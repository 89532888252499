import gradesIcon from '../../images/circle_icons/grades.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import GradesSortButton from './GradesSortButton';
import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function GradesTable({ loading, data }) {
  const years = [...new Set(data?.map((d) => d.end_year))].sort().reverse();
  const [sortYear, setSortYear] = useState('');
  const [activeButton, setActiveButton] = useState(0);

  const handleClick = (item) => {
    setSortYear(item);
  };

  useEffect(() => {
    setSortYear(years[0]);
  }, [data]);

  return (
    <section className='flex w-full flex-col text-brandText xl:flex-row xl:space-x-8'>
      <div className='mx-auto flex h-auto w-full flex-col xl:w-96'>
        <div className='flex flex-row flex-wrap justify-evenly space-x-3 overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white px-4 pt-6 pb-3 shadow-md sm:px-6 lg:px-8 xl:flex-col xl:space-x-0 xl:space-y-7 xl:py-12'>
          {years?.map((item, index) => (
            <GradesSortButton
              id={index}
              key={index}
              item={item}
              setActiveButton={setActiveButton}
              active={activeButton === index ? true : false}
              handleClick={handleClick}
            />
          ))}
        </div>
      </div>
      <div className='mt-5 flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md xl:mt-0'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img
                className='h-12 w-12 sm:h-14 sm:w-14'
                src={gradesIcon}
                alt='Grades'
              />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>Grades</h2>
            <p className='ml-2 text-sm'>
              {parseInt(sortYear) - 1}-{sortYear}
            </p>
          </div>
        </div>
        <div>
          <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
            <thead>
              <tr className='text-base'>
                <th className='p-4 pl-5 pt-1 pb-1 text-left text-base font-medium md:pl-14 xl:text-2xl'>
                  Course
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
                  Task
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
                  Grade&nbsp;Lv
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
                  T1
                </th>
                <th className='p-4  pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
                  T2
                </th>
                <th className='p-4 pr-9 pt-1 pb-1 text-left text-base font-medium sm:pr-14 md:text-2xl'>
                  T3
                </th>
              </tr>
            </thead>
            <tbody className='bg-white text-brandText'>
              {data
                ?.filter((grade) => grade.end_year === sortYear)
                .map((item, itemIdx) => (
                  <tr
                    key={itemIdx}
                    className={itemIdx % 2 === 0 ? undefined : 'bg-slate-50'}
                  >
                    <td className='p-4 py-2 pl-5 font-medium md:pl-14'>
                      {item?.course}
                    </td>
                    <td className='p-4 py-2 font-medium'>{item?.task}</td>
                    <td className='p-4 py-2 text-center font-medium'>
                      {item?.grade_level_crs}
                    </td>
                    <td className='p-4 py-2 text-center font-medium'>
                      {item?.t1}
                    </td>
                    <td className='p-4 py-2 text-center font-medium'>
                      {item?.t2}
                    </td>
                    <td className='p-4 py-2 pr-9 text-center font-medium sm:pr-14'>
                      {item?.t3}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <FormSpinner loading={loading} />
          <NoRecords loading={loading} data={data} />
        </div>
      </div>
    </section>
  );
}
