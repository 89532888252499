export default function TierPyramid({ data }) {
  return (
    <div className='tier-pyramid flex h-80 w-96 flex-col'>
      <div
        className={`flex h-[33.33%] flex-col items-center justify-center bg-[#6ce44e]`}
      >
        <p className='text-3xl font-extrabold text-white'>
          {data?.tier_1_percent?.toFixed(1)}%
        </p>
        <p className='text-base font-bold text-white'>Tier 1</p>
      </div>
      <div className='flex h-[33.33%] flex-col items-center justify-center bg-[#e8e813]'>
        <p className='text-3xl font-extrabold text-white'>
          {data?.tier_2_percent?.toFixed(1)}%
        </p>
        <p className='text-base font-bold text-white'>Tier 2</p>
      </div>
      <div className='flex h-[33.33%] flex-col items-center justify-start bg-[#d5333f] pt-1'>
        <p className='text-3xl font-extrabold text-white'>
          {data?.tier_3_percent?.toFixed(1)}%
        </p>
        <p className='text-base font-bold text-white'>Tier 3</p>
      </div>
    </div>
  );
}
