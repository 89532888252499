import React, { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';
import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function StaffManagementTable({ loading, data }) {
  const [open, setOpen] = useState(false);
  const [student, setStudent] = useState(null);
  const [filterByStudentState, setFilterByStudentState] = useState(false);
  const [displayData, setDisplayData] = useState();

  const navigate = useNavigate();

  const navigateToForm = (item) => {
    navigate('/mtss-management/update-user', {
      state: { item: item },
    });
  };

  let people = data;

  const [query, setQuery] = useState('');
  /* const [selectedPerson, setSelectedPerson] = useState(); */

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => {
          return person.user.last_name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  useEffect(() => {
    setDisplayData(filteredPeople);
  }, [filteredPeople]);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:items-center md:flex'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:max-w-sm'>
          <Combobox
            as='div'
            /* value={selectedPerson}
            onChange={setSelectedPerson} */
          >
            <div className='relative mt-2'>
              <Combobox.Input
                className='w-full rounded-md border-2 border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(person) => person?.first_name}
                autoComplete='off'
                placeholder='Filter by Last Name'
              />
            </div>
          </Combobox>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Name
              </th>
              <th
                scope='col'
                className='table-cell px-3 py-3.5 text-left text-lg font-bold text-white'
              >
                SST Member
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                SST Schools
              </th>
              <th
                scope='col'
                className='table-cell px-3 py-3.5 text-left text-lg font-bold text-white'
              >
                Caregiver
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Caregiver Schools
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <button
                    onClick={() => {
                      navigateToForm(item);
                    }}
                    className='rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary lg:hover:scale-110'
                  >
                    <span className='font-bold text-brandText lg:hidden'>
                      Name:{' '}
                    </span>
                    {item?.user?.first_name} {item?.user?.last_name}
                  </button>
                  <dl className='font-normal lg:hidden'>
                    <dt className='sr-only'>SST Schools</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>SST Schools: </span>
                      {item?.sst_schools?.map((school) => (
                        <div className='flex flex-row items-center'>
                          <dd className='text-brandText'>{school.name}</dd>
                        </div>
                      ))}
                    </dd>
                    <dt className='sr-only'>Caregiver Schools</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Caregiver Schools: </span>
                      {item?.cg_schools?.map((school, itemIdx) => (
                        <div
                          key={itemIdx}
                          className='flex flex-row items-center'
                        >
                          <dd className='text-brandText'>{school.name}</dd>
                        </div>
                      ))}
                    </dd>
                  </dl>
                </td>
                <td className='table-cell px-3 py-4 text-sm font-normal text-brandText'>
                  {item?.is_sst ? (
                    <CheckCircleIcon className='ml-5 h-5 w-5 text-brandPrimary' />
                  ) : (
                    <XCircleIcon className='ml-5 h-5 w-5 text-gray-300' />
                  )}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 lg:table-cell'>
                  {item?.sst_schools?.map((school, itemIdx) => (
                    <div key={itemIdx} className='flex flex-row items-center'>
                      <dd className='text-brandText'>{school.name}</dd>
                    </div>
                  ))}
                </td>
                <td className='table-cell px-3 py-4 text-sm font-normal text-brandText'>
                  {item?.is_caregiver ? (
                    <CheckCircleIcon className='ml-5 h-5 w-5 text-brandPrimary' />
                  ) : (
                    <XCircleIcon className='ml-5 h-5 w-5 text-gray-300' />
                  )}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 lg:table-cell'>
                  {item?.cg_schools?.map((school, itemIdx) => (
                    <div key={itemIdx} className='flex flex-row items-center'>
                      <dd className='text-brandText'>{school.name}</dd>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
    </div>
  );
}
