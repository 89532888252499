import behavioralIncidentsIcon from '../../images/circle_icons/behavioral_incidents.svg';
import detentionsIcon from '../../images/circle_icons/detentions.svg';
import { Oval } from 'react-loader-spinner';
/* import suspensionIcon from '../../images/circle_icons/suspension.svg'; */

export default function BehavioralWellnessCard({ loading, studentCounts }) {
  return (
    <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-4 pb-5 text-brandText shadow-md'>
      <div className='mt-3 h-10 bg-gradient-to-r from-slate-100 to-white'></div>
      <div className='-mt-[54px] flex flex-col justify-evenly sm:flex-row'>
        <div className='p-4 pb-0.5 font-medium'>
          <div className='flex flex-col items-center'>
            <h2 className='p-4 pt-1 pb-1 text-center text-xl font-medium sm:text-2xl'>
              Incidents
            </h2>
            <div className='flex w-auto flex-col items-center pt-4'>
              {loading ? (
                <div className='py-6'>
                  <Oval
                    height={80}
                    width={80}
                    color='#0CB2DA'
                    wrapperStyle={{}}
                    wrapperClass=''
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor='#e5e7eb'
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <>
                  <div className='flex w-auto'>
                    <img
                      className='h-12 w-12 sm:h-14 sm:w-14 lg:h-16 lg:w-16'
                      src={behavioralIncidentsIcon}
                      alt='Behavioral Incidents'
                    />
                  </div>
                  <p className='mt-2 text-4xl font-semibold text-brandPrimary'>
                    {studentCounts !== null && studentCounts[0]?.incident_count}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='py-4 pb-0.5 font-medium'>
          <div className='flex flex-col items-center'>
            <div className='mt-3 flex h-10 w-full bg-gradient-to-r from-slate-100 to-white sm:hidden'></div>
            <h2 className='-mt-[38px] p-4 pt-1 pb-1 text-center text-xl font-medium sm:mt-0 sm:text-2xl'>
              Demerits
            </h2>
            <div className='flex w-auto flex-col items-center pt-4'>
              {loading ? (
                <div className='py-6'>
                  <Oval
                    height={80}
                    width={80}
                    color='#0CB2DA'
                    wrapperStyle={{}}
                    wrapperClass=''
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor='#e5e7eb'
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <>
                  <div className='flex w-auto'>
                    <img
                      className='h-12 w-12 sm:h-14 sm:w-14 lg:h-16 lg:w-16'
                      src={detentionsIcon}
                      alt='Detentions'
                    />
                  </div>
                  <p className='mt-2 text-4xl font-semibold text-brandPrimary'>
                    {studentCounts !== null && studentCounts[0]?.demerit_count}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className='p-4 pb-0.5 font-medium'>
          <div className='flex flex-col items-center'>
            <h2 className='p-4 pt-1 pb-1 text-center text-2xl font-medium'>
              Suspensions
            </h2>
            <div className='flex w-auto flex-col items-center pt-4'>
              <div className='flex w-auto'>
                <img
                  className='h-16 w-16'
                  src={suspensionIcon}
                  alt='Suspensions'
                />
              </div>
              <p className='mt-2 text-4xl font-semibold text-brandPrimary'>
                {studentCounts?.referrals}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

BehavioralWellnessCard.defaultProps = {
  studentCounts: null,
};
