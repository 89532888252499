import actionsIcon from '../../images/circle_icons/actions.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function ActionsTable({ loading, data }) {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToForm = (item) => {
    navigate('/mtss/action-details', {
      state: { item: item, from: location.pathname },
    });
  };

  return (
    <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 text-brandText shadow-md'>
      <div className='flex flex-row items-end'>
        <div>
          <div className='ml-3 flex'>
            <img
              className='h-12 w-12 sm:h-14 sm:w-14'
              src={actionsIcon}
              alt='Actions'
            />
          </div>
        </div>
        <div>
          <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>Actions</h2>
        </div>
      </div>
      <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
        <thead>
          <tr className='text-base'>
            <th className='p-4 pl-9 pt-1 pb-1 text-left font-medium sm:pl-14 md:font-bold'>
              Category
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Subject
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Concern
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Treatment
            </th>
            <th className='p-4 pt-1 pb-1 text-left  font-medium md:font-bold'>
              Check&#8209;In
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Supervisor
            </th>
            <th className='p-4 pr-9 pt-1 pb-1 text-left font-medium sm:pr-14 md:font-bold'>
              Caregiver
            </th>
          </tr>
        </thead>
        <tbody className='bg-white text-brandText'>
          {data?.map((item) => (
            <tr key={item?.id}>
              <td className='p-4 pl-9 pt-2 pb-0.5 font-medium sm:pl-14'>
                {item?.category?.category_name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.subject?.subject_name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.concern?.concern_name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium text-brandPrimary hover:text-brandPrimaryLight'>
                <button
                  onClick={() => {
                    navigateToForm(item);
                  }}
                >
                  {item?.treatment?.treatment_name}
                </button>
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>{item?.checkin}</td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.supervisor?.name}
              </td>
              <td className='p-4 pr-9 pt-2 pb-0.5 font-medium sm:pr-14'>
                {item?.caregiver?.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <FormSpinner loading={loading} />
      <NoRecords loading={loading} data={data} />
    </div>
  );
}
