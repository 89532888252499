import { useState, useEffect } from 'react';

export default function FilterMultiCheck({
  label,
  data,
  setSelectedData,
  dotName,
  idSelector,
}) {
  const [selected, setSelected] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;

    setSelected((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value),
    );
  };

  useEffect(() => {
    if (data?.length > 1 && !dataIsLoaded) {
      let tempData = data?.map((a) => a.id.toString());
      setSelected(tempData);
      setDataIsLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    setSelectedData(selected);
  }, [selected]);

  return (
    <fieldset className=''>
      <div>
        <legend className='sr-only'>{label}</legend>
        <p
          className={`mx-auto mb-2 text-center text-base font-bold text-white`}
        >
          {label}
        </p>
      </div>
      <div className='flex flex-col space-y-5'>
        {data?.map((item, itemIdx) => (
          <div key={itemIdx} className='relative flex items-start pl-4'>
            <div className='flex h-5 items-center'>
              <input
                /* id={`item[dotName]${itemIdx}`} */
                id={`${idSelector}${itemIdx}`}
                value={item?.id}
                defaultChecked={true}
                type='checkbox'
                /* checked={selected.some((val) => val === item.id)} */
                onChange={handleChange}
                className='h-4 w-4 rounded border-white text-brandPrimaryLight focus:ring-brandPrimaryLight'
              />
            </div>
            <div className='ml-3 mr-3 text-sm'>
              <label
                /* htmlFor={`item[dotName]${itemIdx}`} */
                htmlFor={`${idSelector}${itemIdx}`}
                className='font-semibold text-white'
              >
                {item[dotName]}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
