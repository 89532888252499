import { ExclamationIcon } from '@heroicons/react/solid';

export default function FullWidthAlert({ message, customClasses }) {
  return (
    <div
      className={`${customClasses} rounded-md bg-yellow-50 p-4 sm:px-6 lg:px-8`}
    >
      <div className='flex items-center'>
        <div className='flex-shrink-0'>
          <ExclamationIcon
            className='h-10 w-10 text-yellow-400'
            aria-hidden='true'
          />
        </div>
        <div className='ml-3'>
          <div className='text-sm text-yellow-700'>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
