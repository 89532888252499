export default function NoRecords({ loading, data }) {
  return (
    <>
      {!loading && data?.length === 0 && (
        <div className='flex h-96 flex-col items-center justify-center text-center text-xl font-medium text-brandText'>
          <h2>No Records</h2>
        </div>
      )}
    </>
  );
}
