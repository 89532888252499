export default function DatePicker({
  label,
  name,
  data,
  setData,
  labelColor,
  borderColor,
}) {
  return (
    <div className='flex w-full flex-col'>
      <label
        htmlFor={name}
        className={`${labelColor} block text-sm font-medium text-brandText`}
      >
        {label}
      </label>
      <div className={`mt-1`}>
        <input
          type='date'
          name={name}
          id={name}
          onChange={(e) => {
            setData(e.target.value);
          }}
          value={data}
          className={`${borderColor} block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
        />
      </div>
    </div>
  );
}
