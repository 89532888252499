import SidebarLayout from '../../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/MtssManagement/Table';
import AccessWrapper from '../../components/AccessWrapper';

export default function Categories() {
  let { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [treatments, setTreatments] = useState([]);

  useEffect(() => {
    getTreatments();
    postPageView(baseUrl, user.name, user.role, 'mtss-management/treatments');
  }, []);

  let getTreatments = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/treatments/manage_list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setTreatments(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <Table
                loading={loading}
                data={treatments}
                path={'/mtss/treatment-update/'}
                dotSelector={'treatment_name'}
                createNewLink={'/mtss/add-treatment'}
              />
            </div>
          }
          heading={'Treatments'}
        />
      </div>
    </AccessWrapper>
  );
}
