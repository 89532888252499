import TeachersComboBox from '../../formComponents/TeachersComboBox';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import SelectMenu from '../../formComponents/SelectMenu';
import DatePicker from '../../formComponents/DatePicker';
import { useNavigate, useLocation } from 'react-router-dom';
import Notification from '../../formComponents/Notification';
import TodaysDate from '../../../utils/TodaysDate';
import UpdateSelectMenu from '../../formComponents/UpdateSelectMenu';

export default function AddActionForm() {
  const { authTokens, logoutUser, baseUrl, user } = useContext(AuthContext);

  /* state variables to hold json data from the back-end */
  const [concerns, setConcerns] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [sstMembers, setSstMembers] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [assessments, setAssessments] = useState([]);

  /* state variables to hold form data to submit to the back-end */
  const [selectedPerson, setSelectedPerson] = useState();
  const [referralId, setReferralId] = useState(undefined);
  const [addedActionsId, setAddedActionsId] = useState(null);
  const [watchlistId, setWatchlistId] = useState(null);
  const [resolutionId, setResolutionId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedConcern, setSelectedConcern] = useState('');
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [goal, setGoal] = useState('');
  const [selectedSupervisor, SetSelectedSupervisor] = useState('');
  const [selectedCaregiver, SetSelectedCaregiver] = useState('');
  const [startDate, setStartDate] = useState(TodaysDate());
  const [checkIn, setCheckIn] = useState(undefined);
  const [comment, setComment] = useState('');
  const [selectedAssessment, setSelectedAssessment] = useState('');

  const [goalLabelColor, setGoalLabelColor] = useState('text-brandText ');
  const [goalBorderColor, setGoalBorderColor] = useState('border-gray-300 ');
  const [supervisorLabelColor, setSupervisorLabelColor] =
    useState('text-brandText ');
  const [supervisorBorderColor, setSupervisorBorderColor] =
    useState('border-gray-300 ');
  const [caregiverLabelColor, setCaregiverLabelColor] =
    useState('text-brandText ');
  const [caregiverBorderColor, setCaregiverBorderColor] =
    useState('border-gray-300 ');
  const [startDateLabelColor, setStartDateLabelColor] =
    useState('text-brandText ');
  const [startDateBorderColor, setStartDateBorderColor] =
    useState('border-gray-300 ');
  const [checkInLabelColor, setCheckInLabelColor] = useState('text-brandText ');
  const [checkInBorderColor, setCheckInBorderColor] =
    useState('border-gray-300 ');

  const [show, setShow] = useState(false);

  const validateFormData = (e) => {
    e.preventDefault();
    if (
      goal?.trim().length >= 1 &&
      selectedCategory !== '' &&
      selectedSubject !== '' &&
      selectedSupervisor !== '' &&
      selectedAssessment !== '' &&
      selectedCaregiver !== '' &&
      startDate !== null &&
      checkIn !== undefined
    ) {
      handleSubmit(e);
    } else {
      setShow(true);
      if (goal?.trim().length < 1) {
        setGoalLabelColor('text-red-500 ');
        setGoalBorderColor('border-red-500 ');
      } else {
        setGoalLabelColor('text-brandText ');
        setGoalBorderColor('border-gray-300 ');
      }
      if (selectedSupervisor === '') {
        setSupervisorLabelColor('text-red-500 ');
        setSupervisorBorderColor('border-red-500 ');
      } else {
        setSupervisorLabelColor('text-brandText ');
        setSupervisorBorderColor('border-gray-300 ');
      }
      if (selectedCaregiver === '') {
        setCaregiverLabelColor('text-red-500 ');
        setCaregiverBorderColor('border-red-500 ');
      } else {
        setCaregiverLabelColor('text-brandText ');
        setCaregiverBorderColor('border-gray-300 ');
      }
      if (startDate === null) {
        setStartDateLabelColor('text-red-500 ');
        setStartDateBorderColor('border-red-500 ');
      } else {
        setStartDateLabelColor('text-brandText ');
        setStartDateBorderColor('border-gray-300 ');
      }
      if (checkIn === undefined) {
        setCheckInLabelColor('text-red-500 ');
        setCheckInBorderColor('border-red-500 ');
      } else {
        setCheckInLabelColor('text-brandText ');
        setCheckInBorderColor('border-gray-300 ');
      }
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getAddedActionsId();
    getWatchlistId();
    getCategories();
    getSubjects();
    getConcerns();
    getTreatments();
    getAssessments();
    getSstMembers();
    getCaregivers();
  }, []);

  useEffect(() => {
    setSelectedPerson(location?.state?.studentId);
    setReferralId(location?.state?.referralId);
  }, [location.state.studentId, location.state.referralId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/mtss/interventions/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          student: selectedPerson,
          category: selectedCategory.id,
          subject: selectedSubject.id,
          concern: selectedConcern.id,
          treatment: selectedTreatment.id,
          progress_assessment: selectedAssessment.id,
          goal: goal,
          supervisor: selectedSupervisor.id,
          caregiver: selectedCaregiver.id,
          start_date: startDate,
          ckeckin: checkIn,
        }),
      });

      let data = await response.json();

      if (response.status === 201) {
        if (referralId !== undefined) {
          const response2 = await fetch(
            `${baseUrl}/mtss/referrals/${referralId}`,
            {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + String(authTokens.access),
              },
              body: JSON.stringify({
                id: referralId,
                resolution: resolutionId,
              }),
            },
          );
          if (response2.status === 200) {
            navigate(-1);
          } else {
            setShow(true);
            setGoalLabelColor('text-brandText ');
            setGoalBorderColor('border-gray-300 ');
            setSupervisorLabelColor('text-brandText ');
            setSupervisorBorderColor('border-gray-300 ');
            setCaregiverLabelColor('text-brandText ');
            setCaregiverBorderColor('border-gray-300 ');
            setStartDateLabelColor('text-brandText ');
            setStartDateBorderColor('border-gray-300 ');
            setCheckInLabelColor('text-brandText ');
            setCheckInBorderColor('border-gray-300 ');
            console.log(data);

            let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                page: '/mtss/add-action',
                district: baseUrl,
                user: user.name,
                error_description:
                  'User failed to add action and received a status code.',
                response_text: JSON.stringify(data),
              }),
            });
          }
        } else {
          navigate(-1);
        }
      } else {
        setShow(true);
        setGoalLabelColor('text-brandText ');
        setGoalBorderColor('border-gray-300 ');
        setSupervisorLabelColor('text-brandText ');
        setSupervisorBorderColor('border-gray-300 ');
        setCaregiverLabelColor('text-brandText ');
        setCaregiverBorderColor('border-gray-300 ');
        setStartDateLabelColor('text-brandText ');
        setStartDateBorderColor('border-gray-300 ');
        setCheckInLabelColor('text-brandText ');
        setCheckInBorderColor('border-gray-300 ');
        console.log(data);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/add-action',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to add action and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShow(true);
      setGoalLabelColor('text-brandText ');
      setGoalBorderColor('border-gray-300 ');
      setSupervisorLabelColor('text-brandText ');
      setSupervisorBorderColor('border-gray-300 ');
      setCaregiverLabelColor('text-brandText ');
      setCaregiverBorderColor('border-gray-300 ');
      setStartDateLabelColor('text-brandText ');
      setStartDateBorderColor('border-gray-300 ');
      setCheckInLabelColor('text-brandText ');
      setCheckInBorderColor('border-gray-300 ');
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/add-action',
          district: baseUrl,
          user: user.name,
          error_description: 'User failed to add action and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  const getAddedActionsId = async () => {
    const response = await fetch(
      `${baseUrl}/mtss/resolutions/?resolution_name=Added+Actions`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setAddedActionsId(data[0]?.id);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getWatchlistId = async () => {
    const response = await fetch(
      `${baseUrl}/mtss/resolutions/?resolution_name=Added+to+Watchlist`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setWatchlistId(data[0]?.id);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getConcerns = async () => {
    const response = await fetch(`${baseUrl}/mtss/concerns/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    const data = await response.json();

    if (response.status === 200) {
      setConcerns(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getCategories = async () => {
    const response = await fetch(`${baseUrl}/mtss/categories/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    const data = await response.json();

    if (response.status === 200) {
      setCategories(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getSubjects = async () => {
    const response = await fetch(`${baseUrl}/mtss/subjects/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    const data = await response.json();

    if (response.status === 200) {
      setSubjects(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getTreatments = async () => {
    const response = await fetch(`${baseUrl}/mtss/treatments/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    const data = await response.json();

    if (response.status === 200) {
      setTreatments(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getAssessments = async () => {
    try {
      const response = await fetch(`${baseUrl}/mtss/progress-assessments/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        setAssessments(data);
      } else if (response.statusText === 'Unauthorized') {
        logoutUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSstMembers = async () => {
    const response = await fetch(
      `${baseUrl}/sis/teachers/?active=true&profile__is_sst=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setSstMembers(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getCaregivers = async () => {
    const response = await fetch(
      `${baseUrl}/sis/teachers/?active=true&profile__is_caregiver=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setCaregivers(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  useEffect(() => {
    if (
      addedActionsId !== null &&
      watchlistId !== null &&
      selectedTreatment?.watchlist !== null
    ) {
      if (selectedTreatment?.watchlist === false) {
        setResolutionId(addedActionsId);
      } else if (selectedTreatment?.watchlist === true) {
        setResolutionId(watchlistId);
      }
    }
  }, [addedActionsId, watchlistId, selectedTreatment]);

  // set the selected assessment to 'None'
  useEffect(() => {
    const assessment = assessments?.filter(
      (item) => item?.assessment_name === 'None',
    );
    setSelectedAssessment(assessment[0]);
  }, [assessments]);

  return (
    <section>
      <form
        className='mx-auto flex max-w-3xl flex-col space-y-8 px-4 py-16 sm:px-6 lg:px-8 lg:py-32'
        action=''
      >
        <div>
          <label
            htmlFor={'student'}
            className='block text-sm font-medium text-brandText'
          >
            Student
          </label>
          <input
            className='mt-1 block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
            type='text'
            disabled={true}
            value={`${location?.state?.studentFirstName} ${location?.state?.studentLastName}`}
          />
        </div>
        <SelectMenu
          label={'Category'}
          data={categories}
          selectedData={selectedCategory}
          setSelectedData={setSelectedCategory}
          dotSelector={'category_name'}
        />
        <SelectMenu
          label={'Subject'}
          data={subjects}
          selectedData={selectedSubject}
          setSelectedData={setSelectedSubject}
          dotSelector={'subject_name'}
        />
        <SelectMenu
          label={'Concern'}
          data={concerns}
          selectedData={selectedConcern}
          setSelectedData={setSelectedConcern}
          dotSelector={'concern_name'}
        />
        <SelectMenu
          label={'Treatment'}
          data={treatments}
          selectedData={selectedTreatment}
          setSelectedData={setSelectedTreatment}
          dotSelector={'treatment_name'}
        />
        <UpdateSelectMenu
          label={'Progress Assessment'}
          data={assessments}
          selectedData={selectedAssessment}
          setSelectedData={setSelectedAssessment}
          dotSelector={'assessment_name'}
        />
        {/* <SelectMenu
          label={'Progress Assessment'}
          data={assessments}
          selectedData={selectedAssessment}
          setSelectedData={setSelectedAssessment}
          dotSelector={'assessment_name'}
        /> */}
        <div>
          <label
            htmlFor='goal'
            className={`${goalLabelColor} block text-sm font-medium`}
          >
            Goal*
          </label>
          <div className='mt-1'>
            <textarea
              rows={4}
              name='goal'
              id='goal'
              onChange={(e) => setGoal(e.target.value)}
              value={goal}
              className={`${goalBorderColor} block w-full rounded-md text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
            />
          </div>
        </div>
        <TeachersComboBox
          people={sstMembers}
          selectedPerson={selectedSupervisor}
          setSelectedPerson={SetSelectedSupervisor}
          label={'Supervisor*'}
          labelColor={supervisorLabelColor}
          borderColor={supervisorBorderColor}
          initialValue='Select supervisor'
        />
        <TeachersComboBox
          people={caregivers}
          selectedPerson={selectedCaregiver}
          setSelectedPerson={SetSelectedCaregiver}
          label={'Caregiver*'}
          labelColor={caregiverLabelColor}
          borderColor={caregiverBorderColor}
          initialValue='Select caregiver'
        />
        <DatePicker
          label={'Start Date*'}
          name={'start_date'}
          data={startDate}
          setData={setStartDate}
          labelColor={startDateLabelColor}
          borderColor={startDateBorderColor}
        />
        <DatePicker
          label={'Next Checkup Date*'}
          name={'checkin'}
          data={checkIn}
          setData={setCheckIn}
          labelColor={checkInLabelColor}
          borderColor={checkInBorderColor}
        />
        <div>
          <label
            htmlFor='comment'
            className='block text-sm font-medium text-brandText'
          >
            Comment
          </label>
          <div className='mt-1'>
            <textarea
              rows={4}
              name='comment'
              id='comment'
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              className='block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
            />
          </div>
        </div>
        <button
          onClick={validateFormData}
          className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Submit
        </button>
      </form>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been completed.'
        }
      />
    </section>
  );
}
