import { useNavigate } from 'react-router-dom';
import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function PriorActionsTable({ loading, data }) {
  const navigate = useNavigate();

  const navigateToForm = (item) => {
    navigate('/mtss/action-details', {
      state: { item: item },
    });
  };
  return (
    <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-8 pb-8 text-brandText shadow-md'>
      <table className='w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
        <thead>
          <tr className='text-base'>
            <th className='p-4 pl-9 pt-1 pb-1 text-left font-medium sm:pl-14 md:font-bold'>
              Category
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Subject
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Concern
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Treatment
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Start&nbsp;Date
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Supervisor
            </th>
            <th className='p-4 pt-1 pb-1 text-left font-medium md:font-bold'>
              Caregiver
            </th>
            <th className='p-4 pr-9 pt-1 pb-1 text-left font-medium sm:pr-14 md:font-bold'>
              Outcome
            </th>
          </tr>
        </thead>
        <tbody className='bg-white text-brandText'>
          {data?.map((item) => (
            <tr key={item?.id}>
              <td className='p-4 pl-9 pt-2 pb-0.5 font-medium sm:pl-14'>
                {item?.category?.category_name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.subject?.subject_name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.concern?.concern_name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium text-brandPrimary hover:text-brandPrimaryLight'>
                <button
                  onClick={() => {
                    navigateToForm(item);
                  }}
                >
                  {item?.treatment?.treatment_name}
                </button>
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.start_date}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.supervisor?.name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.caregiver?.name}
              </td>
              <td className='p-4 pr-9 pt-2 pb-0.5 font-medium sm:pr-14'>
                {item?.outcome?.outcome_name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <FormSpinner loading={loading} />
      <NoRecords loading={loading} data={data} />
    </div>
  );
}
