import SidebarLayout from '../../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import StaffManagementTable from '../../components/MtssManagement/StaffManagementTable';
import AccessWrapper from '../../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function NotesAndReferrals() {
  let { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [staffMembers, setStaffMembers] = useState([]);

  useEffect(() => {
    getStaffMembers();
    postPageView(
      baseUrl,
      user.name,
      user.role,
      'mtss-management/staff-management',
    );
  }, []);

  let getStaffMembers = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/core/profiles/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStaffMembers(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <StaffManagementTable loading={loading} data={staffMembers} />
            </div>
          }
          heading={'Staff Management'}
        />
      </div>
    </AccessWrapper>
  );
}
