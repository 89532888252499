import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import SmallListModal from '../../SmallListModal';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function TeacherListTable({
  loading,
  data,
  allData,
  setShowPagination,
  sortTeachersByName,
  sortTeachersByEmail,
  sortTeachersBySchool,
  sortTeachersByTitle,
  sortTeachersByCaregiverStatus,
  sortTeachersBySSTStatus,
  lastSortedColumn,
  nameSortDirection,
  emailSortDirection,
  schoolSortDirection,
  titleSortDirection,
  caregiverSortDirection,
  sstSortDirection,
}) {
  const [displayData, setDisplayData] = useState();
  const [open, setOpen] = useState(false);
  const [schoolData, setSchoolData] = useState([]);

  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/mtss/teacher-details/${item?.id}`);
  };

  const showSchoolModal = (item) => {
    setOpen(true);
    setSchoolData(item);
  };

  let people = allData;

  const [query, setQuery] = useState('');

  let filteredPeople =
    query === ''
      ? people
      : people?.filter((person) => {
          const lowerCaseQuery = query.toLowerCase();
          const name = person?.name?.toLowerCase() || '';
          const teacherEmail = person?.teacher_email?.toLowerCase() || '';
          const schoolName = person?.school?.name?.toLowerCase() || '';
          const title = person?.title?.toLowerCase() || '';

          return (
            name.includes(lowerCaseQuery) ||
            teacherEmail.includes(lowerCaseQuery) ||
            schoolName.includes(lowerCaseQuery) ||
            title.includes(lowerCaseQuery)
          );
        });

  useEffect(() => {
    query !== '' ? setDisplayData(filteredPeople) : setDisplayData(data);
    query !== '' ? setShowPagination(false) : setShowPagination(true);
  }, [query, people, data]);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:items-center md:flex'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:max-w-sm'>
          <div className='relative mt-2'>
            <input
              className='w-full rounded-md border-2 border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
              onChange={(event) => setQuery(event.target.value)}
              type='text'
              placeholder='Filter results'
            />
          </div>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                <button
                  className=''
                  onClick={() => {
                    sortTeachersByName();
                  }}
                >
                  Name{' '}
                  {lastSortedColumn === 'name' &&
                    (nameSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortTeachersByEmail();
                  }}
                >
                  Email{' '}
                  {lastSortedColumn === 'email' &&
                    (emailSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortTeachersBySchool();
                  }}
                >
                  School{' '}
                  {lastSortedColumn === 'school' &&
                    (schoolSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortTeachersByTitle();
                  }}
                >
                  Title{' '}
                  {lastSortedColumn === 'title' &&
                    (titleSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-lg font-bold text-white'
              >
                <button
                  onClick={() => {
                    sortTeachersByCaregiverStatus();
                  }}
                >
                  Caregiver&nbsp;Status&nbsp;
                  {lastSortedColumn === 'caregiver' &&
                    (caregiverSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 pr-4 text-left text-lg font-bold text-white sm:pr-6'
              >
                <button
                  onClick={() => {
                    sortTeachersBySSTStatus();
                  }}
                >
                  SST&nbsp;Status&nbsp;
                  {lastSortedColumn === 'sst' &&
                    (sstSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <button
                    className='truncate rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary lg:hover:scale-110'
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <span className='font-bold text-brandText lg:hidden'>
                      Name:{' '}
                    </span>
                    {item?.name}
                  </button>
                  <dl className='font-normal lg:hidden'>
                    <dt className='sr-only'>Email</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Email: </span>
                      {item?.teacher_email}
                    </dd>
                    <dt className='sr-only lg:hidden'>School</dt>
                    <dd className='mt-1 truncate text-brandText lg:hidden'>
                      <span className='font-bold text-brandText'>School: </span>
                      {item?.school?.name}
                    </dd>
                    <dt className='sr-only'>Title</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Title: </span>
                      {item?.title}
                    </dd>
                  </dl>
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.teacher_email}
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.school?.name}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.title}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-brandText'>
                  {item?.profile?.cg_schools?.length > 0 ? (
                    <div className='flex flex-col items-center justify-center'>
                      <CheckCircleIcon className='h-5 w-5 text-brandPrimary' />
                      <button
                        onClick={() => {
                          showSchoolModal(item?.profile?.cg_schools);
                        }}
                        className='mt-2 font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110'
                      >
                        Schools
                      </button>
                    </div>
                  ) : (
                    <XCircleIcon className='mx-auto h-5 w-5 text-gray-300' />
                  )}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-brandText'>
                  {item?.profile?.sst_schools?.length > 0 ? (
                    <div className='flex flex-col items-center justify-center'>
                      <CheckCircleIcon className='h-5 w-5 text-brandPrimary' />
                      <button
                        onClick={() => {
                          showSchoolModal(item?.profile?.sst_schools);
                        }}
                        className='mt-2 font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110'
                      >
                        Schools
                      </button>
                    </div>
                  ) : (
                    <XCircleIcon className='mx-auto h-5 w-5  text-gray-300' />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
      <SmallListModal data={schoolData} open={open} setOpen={setOpen} />
    </div>
  );
}
