export default function Cards({ cardProps }) {
  return (
    <>
      {/* original Cards component */}
      <section className='mx-auto mt-0 max-w-8xl px-0 sm:mt-20 sm:px-6 lg:mt-24 lg:px-8'>
        <div className='mx-auto'>
          <div className='grid grid-cols-1 justify-between border-t-2  border-brandPrimaryLight shadow-md sm:grid-cols-3 sm:gap-8 sm:border-t-0 sm:shadow-none md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
            {cardProps}
          </div>
        </div>
      </section>
      {/* <section className='mx-auto mt-12 max-w-8xl px-0 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
        <div className='mx-auto'>
          <div className='flex flex-col flex-wrap justify-around border-t-2 border-brandPrimaryLight shadow-md sm:flex-row sm:border-t-0 sm:shadow-none'>
            {cardProps}
          </div>
        </div>
      </section> */}
    </>
  );
}
