import SidebarLayout from '../../components/SidebarLayout';
import ChecksForm from '../../components/sections/MTSS/ChecksForm';
import AccessWrapper from '../../components/AccessWrapper';
import AuthContext from '../../context/AuthContext';
import React, { useEffect, useContext } from 'react';

export default function Checks() {
  const { postPageView, user, baseUrl } = useContext(AuthContext);

  useEffect(() => {
    postPageView(baseUrl, user.name, user.role, 'form/add-check');
  }, []);
  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout propComponents={<ChecksForm />} heading={'Add Check'} />
      </div>
    </AccessWrapper>
  );
}
