import lexileIcon from '../../images/circle_icons/lexile.svg';

export default function AAGLexileReadingMath({ readingScores, mathScores }) {
  const elaCalc = (item) => {
    if (item === null) {
      return ' bg-[#f1f5f9] ';
    } else if (item < 16) {
      return ' bg-[#dc2626] ';
    } else if (item < 26) {
      return ' bg-[#facc15] ';
    } else if (item < 100 || item === 100) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-[#f1f5f9] ';
    }
  };

  const mathCalc = (item) => {
    if (item === null) {
      return ' bg-[#f1f5f9] ';
    } else if (item < 16) {
      return ' bg-[#dc2626] ';
    } else if (item < 26) {
      return ' bg-[#facc15] ';
    } else if (item < 100 || item === 100) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-[#f1f5f9] ';
    }
  };

  return (
    <div className='flex h-full w-full flex-col justify-between space-y-3 text-brandText md:w-48 md:space-y-0'>
      <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-3 text-center shadow-md'>
        <div className='bg-gradient-to-r from-slate-100 to-slate-50 px-16'>
          <h2 className='font-medium'>Lexile</h2>
        </div>
        {readingScores?.length >= 1 ? (
          <p className='pt-2 text-brandText'>{readingScores[0].lexile_score}</p>
        ) : (
          <div>
            <p className='pt-2 text-brandText'>No Records</p>
          </div>
        )}
        <div className='relative -top-16 -mb-10 ml-2 flex h-12 w-12 flex-row items-center justify-center'>
          <img className='h-12 w-12' src={lexileIcon} alt='Lexile' />
        </div>
      </div>
      <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-3 text-center shadow-md'>
        <div className='bg-gradient-to-r from-slate-100 to-slate-50 px-16'>
          <h2 className='font-medium'>Reading</h2>
        </div>
        {readingScores?.length >= 1 ? (
          <p className='pt-2 text-brandText'>
            {readingScores[0]?.unified_scaled_score}
          </p>
        ) : (
          <div>
            <p className='pt-2 text-brandText'>No Records</p>
          </div>
        )}
        {readingScores?.length >= 1 ? (
          <div
            className={`${elaCalc(
              readingScores[0]?.percentile,
            )} relative -top-10 -mb-4 ml-2 flex h-7 w-7 flex-row items-center justify-center rounded-full`}
          ></div>
        ) : (
          <div className='relative -top-10 -mb-4 ml-2 flex h-7 w-7 flex-row items-center justify-center rounded-full bg-[#f1f5f9]'></div>
        )}
      </div>
      <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-3 text-center shadow-md'>
        <div className='bg-gradient-to-r from-slate-100 to-slate-50 px-16'>
          <h2 className='font-medium'>Math</h2>
        </div>
        {mathScores?.length >= 1 ? (
          <p className='pt-2 text-brandText'>
            {mathScores[0]?.unified_scaled_score}
          </p>
        ) : (
          <div>
            <p className='pt-2 text-brandText'>No Records</p>
          </div>
        )}
        {mathScores?.length >= 1 ? (
          <div
            className={`${mathCalc(
              mathScores[0]?.percentile,
            )} relative -top-10 -mb-4 ml-2 flex h-7 w-7 flex-row items-center justify-center rounded-full`}
          ></div>
        ) : (
          <div className='relative -top-10 -mb-4 ml-2 flex h-7 w-7 flex-row items-center justify-center rounded-full bg-[#f1f5f9]'></div>
        )}
      </div>
    </div>
  );
}
