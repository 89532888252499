import AtAGlanceModal from './AtAGlanceModal';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SortDropdown from './SortDropdown';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function CurrentActionsTable({ loading, data }) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [displayData, setDisplayData] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const sortItems = [
    { id: 1, name: 'Student' },
    { id: 2, name: 'Concern' },
    { id: 3, name: 'Treatment' },
    { id: 4, name: 'Caregiver' },
    { id: 5, name: 'SST Member' },
  ];

  const [selected, setSelected] = useState(sortItems[0]);

  const navigateToForm = (item) => {
    navigate('/mtss/action-details', {
      state: { item: item, from: location.pathname },
    });
  };

  useEffect(() => {
    if (selected.name === 'Student') {
      sortByStudent();
    } else if (selected.name === 'Concern') {
      sortByConcern();
    } else if (selected.name === 'Treatment') {
      sortByTreatment();
    } else if (selected.name === 'Caregiver') {
      sortByCaregiver();
    } else if (selected.name === 'SST Member') {
      sortBySstMember();
    } else {
      sortByStudent();
    }
  }, [selected]);

  const handleClick = (item) => {
    setOpen(true);
    setId(item?.id);
  };

  let people = data;

  const [query, setQuery] = useState('');
  /* const [selectedPerson, setSelectedPerson] = useState(); */

  let filteredPeople =
    query === ''
      ? people
      : people?.filter((person) => {
          return person?.student?.last_name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const sortByStudent = () => {
    setDisplayData(
      filteredPeople
        ?.slice()
        .sort((a, b) => (a.student?.last_name > b.student?.last_name ? 1 : -1)),
    );
  };

  const sortByCaregiver = () => {
    setDisplayData(
      filteredPeople
        ?.slice()
        .sort((a, b) => (a.caregiver?.name > b.caregiver?.name ? 1 : -1)),
    );
  };

  const sortBySstMember = () => {
    setDisplayData(
      filteredPeople
        ?.slice()
        .sort((a, b) => (a.supervisor?.name > b.supervisor?.name ? 1 : -1)),
    );
  };

  const sortByConcern = () => {
    setDisplayData(
      filteredPeople
        ?.slice()
        .sort((a, b) =>
          a.concern?.concern_name > b.concern?.concern_name ? 1 : -1,
        ),
    );
  };

  const sortByTreatment = () => {
    setDisplayData(
      filteredPeople
        ?.slice()
        .sort((a, b) =>
          a.treatment?.treatment_name > b.treatment?.treatment_name ? 1 : -1,
        ),
    );
  };

  useEffect(() => {
    setDisplayData(filteredPeople);
    if (selected?.name === 'Student') {
      sortByStudent();
    } else if (selected?.name === 'Concern') {
      sortByConcern();
    } else if (selected?.name === 'Treatment') {
      sortByTreatment();
    } else if (selected?.name === 'Caregiver') {
      sortByCaregiver();
    } else if (selected?.name === 'SST Member') {
      sortBySstMember();
    } else {
      sortByStudent();
    }
  }, [query, people]);

  return (
    <div className='mb-12 px-4 sm:mb-16 sm:px-6 lg:mb-20  lg:px-8'>
      <div className='sm:items-center md:flex'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:mr-5 lg:max-w-sm'>
          <input
            className='mt-1 w-full rounded-md border-2 border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
            onChange={(event) => setQuery(event.target.value)}
            type='text'
            placeholder='Filter by Student Last Name'
          />
        </div>

        <div className='mt-3 w-full flex-row lg:mt-0 lg:max-w-sm'>
          <SortDropdown
            sortItems={sortItems}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                Student
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Concern
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Treatment
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                Caregiver
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                SST
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-lg font-bold text-white'
              >
                Start
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 pr-4 text-left text-lg font-bold text-white sm:pr-6'
              >
                Check
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item) => (
              <tr key={item?.id}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <button
                    className='rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110 hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                    onClick={() => {
                      handleClick(item?.student);
                    }}
                  >
                    <span className='font-bold text-brandText lg:hidden'>
                      Student:{' '}
                    </span>
                    {item?.student?.first_name} {item?.student?.last_name}
                  </button>
                  <dl className='font-normal lg:hidden'>
                    <dt className='sr-only'>Concern</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Concern: </span>
                      {item?.concern?.concern_name}
                    </dd>
                    <dt className='sr-only lg:hidden'>Treatment</dt>
                    <dd className='mt-1 truncate text-brandText lg:hidden'>
                      <button
                        onClick={() => {
                          navigateToForm(item);
                        }}
                        className='rounded-md font-medium text-brandPrimary hover:text-brandPrimaryLight  focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                      >
                        <span className='font-bold text-brandText'>
                          Treatment:{' '}
                        </span>
                        {item?.treatment?.treatment_name}
                      </button>
                    </dd>
                    <dt className='sr-only'>caregiver</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Caregiver: </span>
                      {item?.caregiver?.name}
                    </dd>
                    <dt className='sr-only'>SST</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>SST: </span>
                      {item?.supervisor?.name}
                    </dd>
                  </dl>
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.concern?.concern_name}
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandPrimary hover:text-brandPrimaryLight lg:table-cell'>
                  <button
                    onClick={() => {
                      navigateToForm(item);
                    }}
                    className='rounded-md font-medium duration-300 ease-in-out hover:scale-110 focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                  >
                    {item?.treatment?.treatment_name}
                  </button>
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.caregiver?.name}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.supervisor?.name}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-brandText'>
                  {item?.start_date_mdy}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-brandText'>
                  <dd>{item?.checkin}</dd>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
      <AtAGlanceModal open={open} setOpen={setOpen} id={id} />
    </div>
  );
}
