import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';

export default function SchoolListTable({
  loading,
  data,
  allData,
  setShowPagination,
}) {
  const [displayData, setDisplayData] = useState();

  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/mtss/school-details/${item?.id}`);
  };

  let schools = allData;

  const [query, setQuery] = useState('');

  let filteredSchools =
    query === ''
      ? schools
      : schools?.filter((school) => {
          return school?.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    query != '' ? setDisplayData(filteredSchools) : setDisplayData(data);
    query != '' ? setShowPagination(false) : setShowPagination(true);
  }, [query, schools, data]);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:items-center md:flex'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:max-w-sm'>
          <div className='relative mt-2'>
            <input
              className='w-full rounded-md border-2 border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
              onChange={(event) => setQuery(event.target.value)}
              type='text'
              placeholder='Filter results'
            />
          </div>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 lg:pl-8'
              >
                Name
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='px-4 py-4 text-sm sm:px-6 lg:px-8'>
                  <button
                    className='rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110 hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary'
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    {item?.name}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
    </div>
  );
}
