import { useState, useEffect } from 'react';

export default function MultiCheckBox({
  title,
  hint,
  data,
  previousData,
  setSelectedData,
  dotName,
  labelColor,
  borderColor,
}) {
  const [selected, setSelected] = useState([]);

  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;

    setSelected((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value),
    );
  };

  useEffect(() => {
    setSelectedData(selected);
  }, [selected]);

  return (
    <fieldset className='space-y-5'>
      <div>
        <legend className='sr-only'>{title}</legend>
        <label className={`${labelColor} text-base font-medium text-brandText`}>
          {title}
        </label>
        <p className={`${labelColor} text-sm leading-5 text-brandText`}>
          Please select all that apply.
        </p>
      </div>
      {data?.map((item, itemIdx) => (
        <div key={itemIdx} className='relative flex items-start'>
          <div className='flex h-5 items-center'>
            <input
              id={`item[dotName]${itemIdx}`}
              value={item.id}
              type='checkbox'
              /* checked={selected.some((val) => val === item.id)} */
              onChange={handleChange}
              className='h-4 w-4 rounded border-gray-300 text-brandPrimary focus:ring-brandPrimary'
            />
          </div>
          <div className='ml-3 text-sm'>
            <label
              htmlFor={`item[dotName]${itemIdx}`}
              className='font-medium text-brandText'
            >
              {item[dotName]}
            </label>
            <span id='comments-description' className='text-brandText'>
              {hint}
            </span>
          </div>
        </div>
      ))}
    </fieldset>
  );
}
