import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import TeacherListTable from '../components/sections/Dashboard/TeacherListTable';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';
import Pagination from '../components/Pagination';
import TeacherListFilter from '../components/TeacherListFilter';

export default function TeacherList() {
  let { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [teachers, setTeachers] = useState([]);
  const [schools, setSchools] = useState([]);
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showPagination, setShowPagination] = useState(true);
  const [showOnlyTeachersWithStudents, setShowOnlyTeachersWithStudents] =
    useState('0');
  const [selectedSchools, setSelectedSchools] = useState([]);

  const [lastSortedColumn, setLastSortedColumn] = useState('name');
  const [nameSortDirection, setNameSortDirection] = useState(true);
  const [emailSortDirection, setEmailSortDirection] = useState(true);
  const [schoolSortDirection, setSchoolSortDirection] = useState(true);
  const [titleSortDirection, setTitleSortDirection] = useState(true);
  const [caregiverSortDirection, setCaregiverSortDirection] = useState(true);
  const [sstSortDirection, setSSTSortDirection] = useState(true);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let filteredTeachers = teachers;

  if (selectedSchools.length > 0) {
    filteredTeachers = teachers.filter((teacher) =>
      selectedSchools.includes(teacher.school?.id.toString()),
    );
  }

  const currentTeachers = filteredTeachers.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  useEffect(() => {
    postPageView(baseUrl, user?.name, user?.role, 'teacher-list');
    getSchools();
  }, []);

  useEffect(() => {
    getTeachers();
  }, [showOnlyTeachersWithStudents]);

  let getTeachers = async () => {
    setLoading(true);
    let response = await fetch(
      `${baseUrl}/sis/detailed-teacher-list/?&active=true&has_students=${showOnlyTeachersWithStudents}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setTeachers(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  let getSchools = async () => {
    setLoadingSchools(true);
    let response = await fetch(`${baseUrl}/sis/schools/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data);
      setLoadingSchools(false);
    } else if (response.status === 403) {
      setLoadingSchools(false);
      navigate('/unauthorized');
    }
  };

  const sortTeachersByName = () => {
    const direction = lastSortedColumn === 'name' ? !nameSortDirection : true;
    setNameSortDirection(direction);
    setLastSortedColumn('name');
    const sortedTeachers = [...teachers].sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : '';
      const nameB = b.name ? b.name.toLowerCase() : '';
      return nameA.localeCompare(nameB);
    });
    if (!direction) {
      sortedTeachers.reverse();
    }
    setTeachers(sortedTeachers);
  };

  const sortTeachersByEmail = () => {
    const direction = lastSortedColumn === 'email' ? !emailSortDirection : true;
    setEmailSortDirection(direction);
    setLastSortedColumn('email');
    const sortedTeachers = [...teachers].sort((a, b) => {
      const emailA = a.teacher_email ? a.teacher_email.toLowerCase() : '';
      const emailB = b.teacher_email ? b.teacher_email.toLowerCase() : '';
      return emailA.localeCompare(emailB);
    });
    if (!direction) {
      sortedTeachers.reverse();
    }
    setTeachers(sortedTeachers);
  };

  const sortTeachersBySchool = () => {
    const direction =
      lastSortedColumn === 'school' ? !schoolSortDirection : true;
    setSchoolSortDirection(direction);
    setLastSortedColumn('school');
    const sortedTeachers = [...teachers].sort((a, b) => {
      const schoolNameA = a.school?.name ? a.school.name.toLowerCase() : '';
      const schoolNameB = b.school?.name ? b.school.name.toLowerCase() : '';
      return schoolNameA.localeCompare(schoolNameB);
    });
    if (!direction) {
      sortedTeachers.reverse();
    }
    setTeachers(sortedTeachers);
  };

  const sortTeachersByTitle = () => {
    const direction = lastSortedColumn === 'title' ? !titleSortDirection : true;
    setTitleSortDirection(direction);
    setLastSortedColumn('title');
    const sortedTeachers = [...teachers].sort((a, b) => {
      const titleA = a.title ? a.title.toLowerCase() : '';
      const titleB = b.title ? b.title.toLowerCase() : '';
      return titleA.localeCompare(titleB);
    });
    if (!direction) {
      sortedTeachers.reverse();
    }
    setTeachers(sortedTeachers);
  };

  const sortTeachersByCaregiverStatus = () => {
    const direction =
      lastSortedColumn === 'caregiver' ? !caregiverSortDirection : true;
    setCaregiverSortDirection(direction);
    setLastSortedColumn('caregiver');
    const sortedTeachers = [...teachers].sort((a, b) => {
      const caregiverCountA = a.profile?.cg_schools?.length ?? 0;
      const caregiverCountB = b.profile?.cg_schools?.length ?? 0;
      return caregiverCountB - caregiverCountA;
    });
    if (!direction) {
      sortedTeachers.reverse();
    }
    setTeachers(sortedTeachers);
  };

  const sortTeachersBySSTStatus = () => {
    const direction = lastSortedColumn === 'sst' ? !sstSortDirection : true;
    setSSTSortDirection(direction);
    setLastSortedColumn('sst');
    const sortedTeachers = [...teachers].sort((a, b) => {
      const sstCountA = a.profile?.sst_schools?.length ?? 0;
      const sstCountB = b.profile?.sst_schools?.length ?? 0;
      return sstCountB - sstCountA;
    });
    if (!direction) {
      sortedTeachers.reverse();
    }
    setTeachers(sortedTeachers);
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <TeacherListTable
                sortTeachersByName={sortTeachersByName}
                sortTeachersByEmail={sortTeachersByEmail}
                sortTeachersBySchool={sortTeachersBySchool}
                sortTeachersByTitle={sortTeachersByTitle}
                sortTeachersByCaregiverStatus={sortTeachersByCaregiverStatus}
                sortTeachersBySSTStatus={sortTeachersBySSTStatus}
                loading={loading}
                data={currentTeachers}
                allData={teachers}
                setShowPagination={setShowPagination}
                lastSortedColumn={lastSortedColumn}
                nameSortDirection={nameSortDirection}
                emailSortDirection={emailSortDirection}
                schoolSortDirection={schoolSortDirection}
                titleSortDirection={titleSortDirection}
                caregiverSortDirection={caregiverSortDirection}
                sstSortDirection={sstSortDirection}
              />
              {showPagination && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={filteredTeachers?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  currentItems={currentTeachers}
                />
              )}
            </div>
          }
          heading={'Teachers'}
          TeacherListCurrent={true}
          sortComponent={
            <TeacherListFilter
              setShowOnlyTeachersWithStudents={setShowOnlyTeachersWithStudents}
              schools={schools}
              setSelectedSchools={setSelectedSchools}
            />
          }
        />
      </div>
    </AccessWrapper>
  );
}
