import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';
import FormSpinner from '../../../components/formComponents/FormSpinner';
import NoRecords from '../../../components/formComponents/NoRecords';

export default function ConcernsTable({ loading, data, path, createNewLink }) {
  return (
    <div className='mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
      <div className='sm:flex sm:items-center'></div>
      <div className='flex flex-row items-center justify-end'>
        <div className='flex w-auto items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary'>
          <Link to={createNewLink}>Add</Link>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Name
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:hidden'
              >
                Concern
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Category
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Subject
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Schools
              </th>
              <th
                scope='col'
                className='hidden py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6 xl:table-cell'
              >
                Status
              </th>
              <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                <span className='sr-only'>Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {data?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <span className='font-bold text-brandText xl:hidden'>
                    Name{' '}
                  </span>
                  {item?.concern_name}
                  <dl className='font-normal xl:hidden'>
                    <dt className='sr-only'>Category</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>Category: </span>
                      {item?.category?.category_name}
                    </dd>
                    <dt className='sr-only xl:hidden'>Subject</dt>
                    <dd className='mt-2 truncate text-brandText xl:hidden'>
                      <span className='font-bold text-brandText'>
                        Subject:{' '}
                      </span>
                      {item?.subject?.map((subject, itemIdx) => (
                        <div
                          key={itemIdx}
                          className='ml-16 flex flex-row items-center'
                        >
                          <div className='mr-2 h-2 w-2 rounded-full bg-brandPrimary' />
                          <span className='text-brandText'>
                            {subject?.subject_name}
                          </span>
                        </div>
                      ))}
                    </dd>
                    <dt className='sr-only'>Schools</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>Schools: </span>
                      {item?.school?.map((school, itemIdx) => (
                        <div
                          key={itemIdx}
                          className='ml-16 flex flex-row items-center'
                        >
                          <div className='mr-2 h-2 w-2 rounded-full bg-brandPrimary' />
                          <p className='text-brandText'>{school.name}</p>
                        </div>
                      ))}
                    </dd>
                    <dt className='sr-only'>Status</dt>
                    <dd className='mt-2 truncate'>
                      <span className='font-bold'>Status: </span>
                      {item?.status === true ? (
                        <CheckCircleIcon className='ml-16 -mt-5 h-5 w-5 text-brandPrimary' />
                      ) : (
                        <XCircleIcon className='ml-16 -mt-5 h-5 w-5 text-gray-300' />
                      )}
                    </dd>
                  </dl>
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.category?.category_name}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.subject?.map((subject, itemIdx) => (
                    <div key={itemIdx} className='flex flex-row items-center'>
                      <div className='mr-2 h-2 w-2 rounded-full bg-brandPrimary' />
                      <dd className='text-brandText'>
                        {subject?.subject_name}
                      </dd>
                    </div>
                  ))}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal leading-loose text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.school?.map((school, itemIdx) => (
                    <div key={itemIdx} className='flex flex-row items-center'>
                      <div className='mr-2 h-2 w-2 rounded-full bg-brandPrimary' />
                      <dd className='text-brandText'>{school.name}</dd>
                    </div>
                  ))}
                </td>
                <td className='hidden w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6 xl:table-cell'>
                  {item?.status === true ? (
                    <CheckCircleIcon className='ml-5 h-5 w-5 text-brandPrimary' />
                  ) : (
                    <XCircleIcon className='ml-5 h-5 w-5 text-gray-300' />
                  )}
                </td>
                <td className='py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                  <Link
                    to={`${path}${item.id}`}
                    className='font-medium text-brandPrimary hover:text-brandPrimaryLight'
                  >
                    Edit<span className='sr-only'>Edit</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={data} />
      </div>
    </div>
  );
}
