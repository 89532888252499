import { Link } from 'react-router-dom';

export default function CardSchool({ cardImage, href, cardName, cardIcon }) {
  return (
    <div className='flex items-center justify-center border-b-2 border-brandPrimaryLight bg-brandPrimary ease-in-out sm:rounded-3xl sm:border-0 sm:shadow-md sm:transition sm:duration-300 sm:hover:scale-110'>
      <Link to={`/mtss/mtss-lists/students/${href}`}>
        <div className='relative mx-auto hidden overflow-clip sm:block sm:pt-3'>
          <img className='sm:rounded-3xl' src={cardImage} alt='' />
          <div className='absolute bottom-1 mx-auto flex w-full items-center justify-center px-2 text-center'>
            <p className='truncate text-center text-lg font-bold text-white'>
              {cardName}
            </p>
          </div>
        </div>
        <div className='mobile-service-card flex h-24 w-screen flex-row items-center justify-between bg-brandPrimary px-5 sm:hidden'>
          <div className='flex w-12'>
            <img className='w-full' src={cardIcon} alt='' />
          </div>
          <div className='flex'>
            <p className='truncate text-xl font-bold text-white sm:text-2xl'>
              {cardName}
            </p>
          </div>
          <div className='flex w-12 '></div>
        </div>
      </Link>
    </div>
  );
}
