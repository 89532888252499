import { useEffect, useRef } from "react"
import { select } from 'd3-selection';

export const useD3 = (render, ...dependencies) => {
    const ref = useRef(); 

    useEffect(() => {
        render(select(ref.current));

    }, dependencies); 

    return ref; 
};
