import FormSpinner from '../formComponents/FormSpinner';
import NoRecords from '../formComponents/NoRecords';

export default function BehavioralRecentHistory({ loading, data }) {
  return (
    <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 text-brandText shadow-md'>
      <table className='w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
        <thead>
          <tr className='text-base'>
            <th className='p-4 pl-9 pt-1 pb-1 text-left text-base font-medium sm:pl-14 md:text-2xl'>
              Data
            </th>
            <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Incident
            </th>
            <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Consequence
            </th>
            <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-2xl'>
              Staff
            </th>
            <th className='p-4 pr-9 pt-1 pb-1 text-left text-base font-medium sm:pr-14 md:text-2xl'>
              Notes
            </th>
          </tr>
        </thead>
        <tbody className='bg-white text-brandText'>
          {data?.map((item) => (
            <tr key={item?.id}>
              <td className='p-4 pt-2 pl-9 pb-0.5 font-medium sm:pl-14'>
                {item?.incident_date}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.incident_category?.category}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.consequence?.name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.submitted_by?.name}
              </td>
              <td className='p-4 pr-9 pt-2 pb-0.5 font-medium sm:pr-14'>
                {item?.incident_description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <FormSpinner loading={loading} />
      <NoRecords loading={loading} data={data} />
    </div>
  );
}
