import SidebarLayout from '../components/SidebarLayout';
import ReferralDetail from '../components/sections/MTSS/ReferralDetail';
import { useParams } from 'react-router-dom';
import ReferralPathForward from '../components/sections/MTSS/ReferralPathForward';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function Referral() {
  const { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  const [referralDetails, setReferralDetails] = useState();

  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    getReferralDetails();
    postPageView(baseUrl, user.name, user.role, 'referral');
  }, []);

  let getReferralDetails = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/referrals/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setReferralDetails(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <ReferralDetail
                loading={loading}
                referralDetails={referralDetails}
              />
              <ReferralPathForward referralDetails={referralDetails} />
            </div>
          }
          heading={'SST Referral'}
        />
      </div>
    </AccessWrapper>
  );
}
