import { useEffect, useState } from 'react';

export default function AAGCoursesTable({ studentSchedule, student }) {
  const [showGrades, setShowGrades] = useState(false);

  useEffect(() => {
    if (
      student?.grade_level === '06' ||
      student?.grade_level === '07' ||
      student?.grade_level === '08' ||
      student?.grade_level === '09'
    ) {
      setShowGrades(true);
    } else setShowGrades(false);
  }, [student]);

  return (
    <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
      <table className='w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
        <thead>
          <tr className='text-base text-brandText'>
            <th className='p-4 pl-14 pt-1 pb-1 text-left'>Course</th>
            <th className='p-4 pt-1 pb-1 text-left'>Teacher</th>
            <th className='p-4 pt-1 pb-1 text-left'>Room</th>
            {showGrades && (
              <>
                <th className='p-4 pt-1 pb-1 text-left'>Grade</th>
                <th className='p-4  pt-1 pb-1 text-left'></th>
              </>
            )}
          </tr>
        </thead>
        <tbody className='bg-white text-brandText'>
          {studentSchedule?.map((item) => (
            <tr key={item?.id}>
              <td className='p-4 pl-14 pt-2 pb-0.5 font-medium'>
                {item?.section?.course?.title}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                {item?.section?.polaris_teacher?.name}
              </td>
              <td className='p-4 pt-2 pb-0.5 font-medium'>
                #{item?.section?.room}
              </td>
              {showGrades && (
                <>
                  <td className='p-4 pt-2 pb-0.5 font-medium'>
                    {item?.current_percent
                      ? `${item?.current_percent}%`
                      : 'TBD'}
                  </td>
                  <td className='p-4 pt-2 pb-0.5 font-medium'>
                    {item?.current_letter_grade
                      ? item?.current_letter_grade
                      : 'TBD'}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
