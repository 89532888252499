import SidebarLayout from '../components/SidebarLayout';
import Cards from '../components/Cards';
import Card from '../components/Card';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Unauthorized from '../components/Unauthorized';

/* Tiles */
import internalStandardsTile from '../images/standards_tiles/internal_standards_tile.png';
import externalStandardsTile from '../images/standards_tiles/external_standards_tile.png';
import standardsAdminTile from '../images/standards_tiles/standards_admin_tile.png';

/* Icons */
import internalStandardsIcon from '../images/standards_icons/internal_standards_icon.svg';
import externalStandardsIcon from '../images/standards_icons/external_standards_icon.svg';
import standardsAdminIcon from '../images/standards_icons/standards_admin_icon.svg';

export default function Standards() {
  const { user } = useContext(AuthContext);

  return (
    <>
      {user?.groups?.includes('standards_admin') ? (
        <div>
          <SidebarLayout
            propComponents={
              <div className='pb-12 sm:pb-16 lg:pb-20'>
                <div>
                  <Cards
                    cardProps={
                      <>
                        <Card
                          cardImage={internalStandardsTile}
                          href={'/'}
                          cardName={'Internal Standards'}
                          cardIcon={internalStandardsIcon}
                        />
                        <Card
                          cardImage={externalStandardsTile}
                          href={'/'}
                          cardName={'External Standards'}
                          cardIcon={externalStandardsIcon}
                        />
                        <Card
                          cardImage={standardsAdminTile}
                          href={'/'}
                          cardName={'Standards Admin'}
                          cardIcon={standardsAdminIcon}
                        />
                      </>
                    }
                  />
                </div>
              </div>
            }
            heading={'Standards'}
            standardsCurrent={true}
          />
        </div>
      ) : (
        <div>
          <Unauthorized />
        </div>
      )}
    </>
  );
}
