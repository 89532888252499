import SidebarLayout from '../components/SidebarLayout';
import Cards from '../components/Cards';
import Card from '../components/Card';
import AccessWrapper from '../components/AccessWrapper';

/* Tiles */
import teacherListCard from '../images/dashboard_cards/teacher_list.png';
import courseListCard from '../images/dashboard_cards/course_list.png';
import schoolListCard from '../images/dashboard_cards/school_list.png';

/* Icons */
import teacherListIcon from '../images/dashboard_icons/teacher_list.svg';
import courseListIcon from '../images/dashboard_icons/course_list.svg';
import schoolListIcon from '../images/dashboard_icons/school_list.svg';

export default function Mtss() {
  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <div>
                <Cards
                  cardProps={
                    <>
                      <Card
                        cardImage={teacherListCard}
                        href={'/mtss/teacher-list'}
                        cardName={'Teachers'}
                        cardIcon={teacherListIcon}
                      />
                      <Card
                        cardImage={courseListCard}
                        href={'/mtss/course-list'}
                        cardName={'Courses'}
                        cardIcon={courseListIcon}
                      />
                      <Card
                        cardImage={schoolListCard}
                        href={'/mtss/school-list'}
                        cardName={'Schools'}
                        cardIcon={schoolListIcon}
                      />
                    </>
                  }
                />
              </div>
            </div>
          }
          heading={'School'}
          mtssSchoolCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
