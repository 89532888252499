import actionsIcon from '../../images/circle_icons/actions.svg';
import recentNotesIcon from '../../images/circle_icons/recent_notes.svg';
import behavioralIncidentsIcon from '../../images/circle_icons/behavioral_incidents.svg';

export default function AAGActionsNotesReferrals({ studentCounts }) {
  return (
    <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-4 pb-5 shadow-md'>
      <table className='w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
        <thead>
          <tr className='text-base text-brandText'>
            <th className='p-4 pt-1 pb-1 pl-5 text-center'>Actions</th>
            <th className='p-4 pt-1 pb-1 text-center'>Recent Notes</th>
            <th className='p-4 pt-1 pb-1 text-center'>Referrals</th>
          </tr>
        </thead>
        <tbody className=' bg-white text-brandText'>
          <tr className=''>
            <td className='p-4 pt-3 pb-0.5 font-medium'>
              <div className='flex flex-col items-center'>
                <div className='flex w-auto flex-col items-center'>
                  <div className='flex w-auto'>
                    <img
                      className='h-14 w-14'
                      src={actionsIcon}
                      alt='Actions'
                    />
                  </div>
                  <p className='mt-2 text-4xl font-semibold text-brandPrimary'>
                    {studentCounts?.actions}
                  </p>
                </div>
              </div>
            </td>
            <td className='p-4 pl-8 pt-3 pb-0.5 font-medium'>
              <div className='flex flex-col items-center'>
                <div className='flex w-auto flex-col items-center'>
                  <div className='flex w-auto'>
                    <img
                      className='h-14 w-14'
                      src={recentNotesIcon}
                      alt='Recent Notes'
                    />
                  </div>
                  <p className='mt-2 text-4xl font-semibold text-brandPrimary'>
                    {studentCounts?.notes}
                  </p>
                </div>
              </div>
            </td>
            <td className='p-4 pt-3 pb-0.5 font-medium'>
              <div className='flex flex-col items-center'>
                <div className='flex w-auto flex-col items-center'>
                  <div className='flex w-auto'>
                    <img
                      className='h-14 w-14'
                      src={behavioralIncidentsIcon}
                      alt='Referrals'
                    />
                  </div>
                  <p className='mt-2 text-4xl font-semibold text-brandPrimary'>
                    {studentCounts?.referrals}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
