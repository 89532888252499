import SidebarLayout from '../../components/SidebarLayout';
import ChecksUpdateForm from '../../components/sections/MTSS/ChecksUpdateForm';
import AccessWrapper from '../../components/AccessWrapper';
import AuthContext from '../../context/AuthContext';
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

export default function ChecksUpdate() {
  const params = useParams();
  const id = params?.id;
  const { postPageView, user, baseUrl } = useContext(AuthContext);

  useEffect(() => {
    postPageView(baseUrl, user.name, user.role, 'form/update-check');
  }, []);
  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={<ChecksUpdateForm id={id} />}
          heading={'Update Check'}
        />
      </div>
    </AccessWrapper>
  );
}
