import React from 'react';
import {
  RadialBarChart,
  PolarAngleAxis,
  RadialBar,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function MinimalPassPercentChart({
  title,
  data,
  loading,
  selector,
}) {
  const circleSize = 305;
  return (
    <div className='flex h-96 w-full flex-col items-center justify-center space-x-8 text-brandText'>
      {!loading && data?.length > 0 ? (
        <div className='mx-auto flex h-full w-full items-center justify-center px-6 font-bold text-brandText'>
          <div className='relative mx-auto flex h-full w-[305px] items-center justify-center'>
            <div className='pass-percent-overlay minimal-mask text-text text-semibold absolute top-0 left-0 z-10 -mt-2 flex h-80 w-[305px] items-center justify-center pb-5 text-5xl'>
              {Math.floor(data[0][selector])}%
            </div>
            <ResponsiveContainer>
              <RadialBarChart
                width={circleSize}
                height={circleSize}
                cx={circleSize / 2}
                cy={circleSize / 2}
                innerRadius={110}
                outerRadius={220}
                barSize={80}
                data={data}
                startAngle={90}
                endAngle={-270}
              >
                <PolarAngleAxis
                  type='number'
                  domain={[0, 100]}
                  angleAxisId={0}
                  tick={false}
                />
                <RadialBar
                  background={{ fill: '#e5e7eb' }}
                  clockWise
                  dataKey={selector}
                  cornerRadius={3}
                  fill='#11CCEA'
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className='flex h-full w-full flex-col items-center justify-center pb-5 text-4xl font-normal text-gray-200'>
          {loading ? (
            <div>
              <Oval
                height={80}
                width={80}
                color='#0CB2DA'
                wrapperStyle={{}}
                wrapperClass=''
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor='#e5e7eb'
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <div>No Records</div>
          )}
        </div>
      )}
      <div className='flex w-full justify-center'>
        <p className='-mt-12 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
          {title}
        </p>
      </div>
    </div>
  );
}
