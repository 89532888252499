import React from 'react';
import {
  RadialBarChart,
  PolarAngleAxis,
  RadialBar,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function PassPercentChart({
  title,
  subtitle,
  icon,
  data,
  loading,
  selector,
  customNoDataMessage = 'No Records',
  customMessageClasses = 'text-4xl text-gray-200',
}) {
  const circleSize = 305;
  return (
    <div className='flex h-96 w-full flex-row space-x-8 text-brandText'>
      <div className='flex h-full w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-3 shadow-md'>
        <div className='flex w-full flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <img
                className='h-12 w-12 sm:h-14 sm:w-14'
                src={icon}
                alt='Grades'
              />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>{title}</h2>
            <p className='ml-2 text-sm'>{subtitle}</p>
          </div>
        </div>
        {!loading && data?.length > 0 ? (
          <div className='mx-auto -mt-5 flex h-full w-full items-center justify-center px-6 font-bold text-brandText'>
            <div className='relative mx-auto flex h-full w-[305px] items-center justify-center'>
              <div className='pass-percent-overlay text-text text-semibold absolute top-0 left-0 z-10 flex h-80 w-[305px] items-center justify-center pb-5 text-5xl'>
                {Math.floor(data[0][selector])}%
              </div>
              <ResponsiveContainer>
                <RadialBarChart
                  width={circleSize}
                  height={circleSize}
                  cx={circleSize / 2}
                  cy={circleSize / 2}
                  innerRadius={110}
                  outerRadius={220}
                  barSize={80}
                  data={data}
                  startAngle={90}
                  endAngle={-270}
                >
                  <PolarAngleAxis
                    type='number'
                    domain={[0, 100]}
                    angleAxisId={0}
                    tick={false}
                  />
                  <RadialBar
                    background={{ fill: '#e5e7eb' }}
                    clockWise
                    dataKey={selector}
                    cornerRadius={3}
                    fill='#11CCEA'
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div
            className={`${customMessageClasses} flex h-full w-full flex-col items-center justify-center pb-5 font-normal`}
          >
            {loading ? (
              <div>
                <Oval
                  height={80}
                  width={80}
                  color='#0CB2DA'
                  wrapperStyle={{}}
                  wrapperClass=''
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor='#e5e7eb'
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div>{customNoDataMessage}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
