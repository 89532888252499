import { Oval } from 'react-loader-spinner';

export default function FormSpinner({ loading }) {
  return (
    <>
      {loading && (
        <div className='flex h-96 w-full flex-col items-center justify-center'>
          <Oval
            height={80}
            width={80}
            color='#0CB2DA'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#e5e7eb'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  );
}
