import SidebarLayout from '../../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import ConcernsTable from '../../components/sections/Dashboard/ConcernsTable';
import AccessWrapper from '../../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function Concerns() {
  let { authTokens, user, postPageView, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [concerns, setConcerns] = useState([]);

  useEffect(() => {
    getConcerns();
    postPageView(baseUrl, user.name, user.role, 'mtss-management/concerns');
  }, []);

  let getConcerns = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/concerns/manage_list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setConcerns(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <ConcernsTable
                loading={loading}
                data={concerns}
                path={'/mtss/concern-update/'}
                createNewLink={'/mtss/add-concern'}
              />
            </div>
          }
          heading={'Concerns'}
        />
      </div>
    </AccessWrapper>
  );
}
