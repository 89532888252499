import referralsIcon from '../../../images/circle_icons/open_referrals.svg';
import checkinsIcon from '../../../images/circle_icons/check_ins.svg';
import actionsIcon from '../../../images/circle_icons/actions.svg';
import { Link } from 'react-router-dom';

export default function AttendanceCard({ data, user }) {
  return (
    <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-4 pb-4 text-brandText shadow-md'>
      <div className='mt-3 h-10 bg-gradient-to-r from-slate-100 to-white'></div>
      <div className='-mt-[54px] flex flex-col justify-evenly md:flex-row'>
        <div className='p-4 pb-0.5 font-medium'>
          <div className='flex flex-col items-center'>
            <h2 className='p-4 pt-1 pb-1 text-center text-lg font-medium sm:text-xl'>
              {user?.role === 'Teacher'
                ? 'My Open Referrals'
                : 'Open Referrals'}
            </h2>
            <Link to='/mtss/referrals'>
              <div className='flex w-auto flex-col items-center pt-4'>
                <div className='flex w-auto'>
                  <img
                    className='h-12 w-12 sm:h-14 sm:w-14 lg:h-16 lg:w-16'
                    src={referralsIcon}
                    alt='Referrals icon'
                  />
                </div>
                <p className='mt-4 text-4xl font-semibold text-brandPrimary'>
                  {data !== null ? data?.referrals : 0}
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className='mt-3 h-10 bg-gradient-to-r from-slate-100 to-white md:hidden'></div>
        <div className='-mt-[54px] p-4 pb-0.5 font-medium md:mt-0'>
          <div className='flex flex-col items-center'>
            <h2 className='p-4 pt-1 pb-1 text-center text-lg font-medium sm:text-xl'>
              My Check-Ins Due
            </h2>
            <Link to='/actions-and-checks'>
              <div className='flex w-auto flex-col items-center pt-4'>
                <div className='flex w-auto'>
                  <img
                    className='h-12 w-12 sm:h-14 sm:w-14 lg:h-16 lg:w-16'
                    src={checkinsIcon}
                    alt='Check-ins icon'
                  />
                </div>
                <p className='mt-4 text-4xl font-semibold text-brandPrimary'>
                  {data !== null ? data?.checkins_due : 0}
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className='mt-3 h-10 bg-gradient-to-r from-slate-100 to-white md:hidden'></div>
        <div className='-mt-[54px] p-4 pb-0.5 font-medium md:mt-0'>
          <div className='flex flex-col items-center'>
            <h2 className='p-4 pt-1 pb-1 text-center text-lg font-medium sm:text-xl'>
              My Actions
            </h2>
            <Link to='/actions-and-checks'>
              <div className='flex w-auto flex-col items-center pt-4'>
                <div className='flex w-auto'>
                  <img
                    className='h-12 w-12 sm:h-14 sm:w-14 lg:h-16 lg:w-16'
                    src={actionsIcon}
                    alt='Actions Icon'
                  />
                </div>
                <p className='mt-4 text-4xl font-semibold text-brandPrimary'>
                  {data !== null ? data?.actions : 0}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

AttendanceCard.defaultProps = {
  data: null,
};
