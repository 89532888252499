import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  XAxis,
  Area,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function PassRateTrendChart({
  data,
  loading,
  selectorOne,
  selectorTwo,
  customNoDataMessage = 'No Records',
  customMessageClasses = 'text-4xl text-gray-200',
}) {
  const [uniqueDates, setUniqueDates] = useState([]);
  const [chartRendered, setChartRendered] = useState(false);

  const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fill={'#636363'}
        color='red'
        fontSize={13}
        textAnchor='middle'
      >
        {value}%
      </text>
    );
  };

  useEffect(() => {
    if (!loading) {
      // Delay the re-render by 1.25 seconds
      const timeout = setTimeout(() => {
        setChartRendered(true);
      }, 1250);

      return () => clearTimeout(timeout);
    }
  }, [loading]);

  useEffect(() => {
    const data2 = data;
    setUniqueDates(Array.from(new Set(data2.map((item) => item[selectorOne]))));
  }, [data, selectorOne]);

  return (
    <div className='flex h-96 w-full flex-row text-brandText'>
      <div className='flex h-full w-full flex-col'>
        {!loading && data?.length > 0 ? (
          <div className='mx-auto flex h-full w-full items-center justify-center font-bold text-brandText'>
            {chartRendered && (
              <ResponsiveContainer width='100%' height='100%'>
                <AreaChart
                  width={500}
                  height={400}
                  data={data}
                  margin={{
                    top: 15,
                    right: 30,
                    left: 30,
                    bottom: 50,
                  }}
                >
                  <defs>
                    <linearGradient
                      id='colorUvTopToBottom'
                      x1='0%'
                      y1='0%'
                      x2='0%'
                      y2='100%'
                    >
                      <stop offset='70%' stopColor='#0CB2DA' stopOpacity={1} />
                      <stop offset='100%' stopColor='white' stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey={selectorOne}
                    tickLine={false}
                    axisLine={false}
                    fontSize={20}
                    fontWeight={700}
                    dy={50}
                    ticks={uniqueDates}
                  />
                  <Area
                    type='linear'
                    dataKey={selectorTwo}
                    stroke='#0b90b0'
                    strokeWidth={2}
                    fill='url(#colorUvTopToBottom)'
                  >
                    <LabelList content={<CustomizedLabel />} />
                  </Area>
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
        ) : (
          <div
            className={`${customMessageClasses} flex h-full w-full flex-col items-center justify-center pb-5 font-normal`}
          >
            {loading ? (
              <div>
                <Oval
                  height={80}
                  width={80}
                  color='#0CB2DA'
                  wrapperStyle={{}}
                  wrapperClass=''
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor='#e5e7eb'
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div>{customNoDataMessage}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
